import React, { Component } from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import API from "langapi/http";
import InputLabel from "@material-ui/core/InputLabel";
import { Box, Button } from "@material-ui/core";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { Link } from "react-router-dom";
import { AddOutlined, DeleteOutlined, EditOutlined } from "@material-ui/icons";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "15px", // override the cell padding for head cells
      paddingRight: "15px",
      fontWeight: "600",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px", // override the cell padding for data cells
      paddingRight: "15px",
      textTransform: "Capitalize",
    },
  },
};

class FaqsList extends Component {
  state = {
    selectedLang: window.location.search.split("=")[1] || "en",
    rows: [],
    columns: [
      {
        name: "Type",
        selector: "type",
        sortable: true,
      },
      {
        name: "Question",
        selector: "question",
        sortable: true,
      },
      {
        name: "Answer",
        selector: "answer",
        sortable: true,
        cell: (row) => (
          <code>
            {row?.answer?.length > 100
              ? row?.answer?.substr(0, 100) + "..."
              : row?.answer}
          </code>
        ),
      },
      {
        name: "Actions",
        selector: "route",
        sortable: true,
        cell: (row) => (
          <div className="d-flex nowrap">
            <EditOutlined
              fontSize="small"
              className="action-icon-edit"
              onClick={() =>
                this.props.history.push(
                  `/admin/faqs/edit/${row?.route}?lang=${this.state.selectedLang}`
                )
              }
            />
            <DeleteOutlined
              fontSize="small"
              className="action-icon-delete"
              onClick={() => this.handleDelete(row?.route)}
            />
          </div>
        ),
      },
    ],
  };

  componentDidMount() {
    API.get(`/faqs?lang=${this.state.selectedLang}`).then((response) => {
      let rows = response.data;
      this.setState({ rows });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedLang !== this.state.selectedLang) {
      API.get(`/faqs?lang=${this.state.selectedLang}`).then((response) => {
        let rows = response.data;
        if (this.state.rows != rows) {
          this.setState({ rows });
        }
      });
    }
  }

  handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this ?")) {
      let token = localStorage.getItem("authToken") || "";
      API.delete(`/faqs/${id}?lang=${this.state.selectedLang}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            alert("Faq deleted successfully!");
          }
        })
        .then(() => {
          API.get(`/faqs?lang=${this.state.selectedLang}`).then((response) => {
            let rows = response.data;
            this.setState({ rows });
          });
        })
        .catch((err) => console.log(err));
    }
  };

  handleChange = (event) => {
    if (event.target.value != this.state.selectedLang) {
      this.setState({ selectedLang: event.target.value });
    }
  };

  render() {
    let { rows, columns } = this.state;
    return (
      <div>
        <Box marginBottom={4}>
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/admin/faqs/add">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddOutlined />}
              >
                Add Faq
              </Button>
            </Link>
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: "20%" }}
            >
              <InputLabel id="lang">Select Language</InputLabel>
              <Select
                labelId="lang"
                id="lang"
                name="lang"
                value={this.state.selectedLang}
                label="Select Language"
                fullWidth
                onChange={this.handleChange}
              >
                <MenuItem value={"en"}>En</MenuItem>
                <MenuItem value={"ar"}>AR</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Box>

        <Card>
          <CardHeader>
            <CardTitle>F.A.Q's List</CardTitle>
          </CardHeader>
          <CardBody>
            <DataTableExtensions
              data={rows}
              columns={columns}
              filter={true}
              filterPlaceholder="Search..."
              exportHeaders={true}
              print={true}
            >
              <DataTable
                noHeader
                pagination
                highlightOnHover
                responsive
                customStyles={customStyles}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default FaqsList;
