import React, { Component } from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import API from "langapi/http";
import { DeleteOutlined } from "@material-ui/icons";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "15px", // override the cell padding for head cells
      paddingRight: "15px",
      fontWeight: "600",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px", // override the cell padding for data cells
      paddingRight: "15px",
      textTransform: "Capitalize",
    },
  },
};

class SubscriberList extends Component {
  state = {
    selectedLang: "en",
    rows: [],
    columns: [
      {
        name: "Date",
        selector: "created_at",
        sortable: true,
        cell: (row) => (
          <p className="text-bold-500 mb-0">
            {new Date(row?.created_at).toLocaleDateString()}{" "}
          </p>
        ),
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
      },
      {
        name: "Actions",
        selector: "id",
        sortable: true,
        cell: (row) => (
          <DeleteOutlined
            fontSize="small"
            className="action-icon-delete"
            onClick={() => this.handleDelete(row?.id)}
          />
        ),
      },
    ],
  };

  componentDidMount() {
    let token = localStorage.getItem("authToken") || "";
    API.get(`/auth/contact-us`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      this.setState({
        rows: response?.data?.filter((x) => x.form_type == "subscriber"),
      });
    });
  }

  handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this ?")) {
      let token = localStorage.getItem("authToken") || "";
      API.delete(`/auth/contact-us/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            alert("Data deleted Succsessfully");
          }
        })
        .then(() => {
          API.get(`/auth/contact-us`)
            .then((response) => {
              let rows = response?.data?.filter(
                (x) => x.form_type == "subscriber"
              );
              this.setState({ rows });
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }
  };

  handleChange = (event) => {
    if (event.target.value != this.state.selectedLang) {
      this.setState({ selectedLang: event.target.value });
    }
  };

  render() {
    let { rows, columns } = this.state;
    return (
      <div>
        <Card>
          <CardHeader>
            <CardTitle>Subscribers List</CardTitle>
          </CardHeader>
          <CardBody>
            <DataTableExtensions
              data={rows}
              columns={columns}
              filter={true}
              filterPlaceholder="Search..."
              exportHeaders={true}
              print={true}
            >
              <DataTable
                noHeader
                pagination
                highlightOnHover
                responsive
                customStyles={customStyles}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default SubscriberList;
