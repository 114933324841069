import React, { Component } from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import API from "langapi/http";
import { Button } from "@material-ui/core";
import { VisibilityOutlined } from "@material-ui/icons";
import OrderDetailModal from "./OrderDetailModal";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "15px", // override the cell padding for head cells
      paddingRight: "15px",
      fontWeight: "600",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px", // override the cell padding for data cells
      paddingRight: "15px",
      textTransform: "Capitalize",
    },
  },
};

class OrderList extends Component {
  state = {
    rows: [],
    date: "",
    dateFrom: "",
    dateTo: "",
    show: false,
    selectReview: "",
    columns: [
      {
        name: "Date",
        selector: "created_at",
        sortable: true,
        cell: (row) => (
          <p className="text-bold-500 mb-0">
            {new Date(row?.created_at).toLocaleDateString()}{" "}
          </p>
        ),
      },
      {
        name: "Customer Name",
        selector: "user_detail",
        sortable: true,
        cell: (row) => (
          <p className="text-bold-500 mb-0">{row?.user_detail?.name}</p>
        ),
      },
      {
        name: "Order No",
        selector: "order_number",
        sortable: true,
      },
      {
        name: "Total Amount",
        selector: "total",
        sortable: true,
      },
      {
        name: "Order Status",
        selector: "status",
        sortable: true,
        cell: (row) => (
          <p
            className={`text-bold-600 mb-0 ${
              row?.status === "ORDERDELIVERED" ? "Order_Delivered" : ""
            }${row?.status === "ORDERCANCELLED" ? "Order_cancel" : ""}`}
          >
            {" "}
            {row?.status === "ORDERPLACED"
              ? "Order Placed"
              : row?.status === "ORDERDISPATCHED"
              ? "Order Dispatched"
              : row?.status === "ORDERDELIVERED"
              ? "Order Delivered"
              : row?.status === "ORDERCONFIRMED"
              ? "Order Confirmed"
              : "Order Canceled"}{" "}
          </p>
        ),
      },
      {
        name: "Promo Code",
        selector: "coupon",
        sortable: true,
        cell: (row) => <p className="text-bold-500 mb-0">{row?.coupon}</p>,
      },
      {
        name: "Actions",
        selector: "id",
        sortable: true,
        cell: (row) => (
          <div className="d-flex nowrap justify-content-center align-items-center">
            <VisibilityOutlined
              fontSize="small"
              className="action-icon-edit"
              onClick={() => {
                this.setState({ show: true });
                this.setState({ selectReview: row });
              }}
            />

            {row?.status === "ORDERPLACED" && (
              <Button
                className="ml-2 order_confirm"
                onClick={() =>
                  this.handleOrderConfirmation(row?.order_number, "confirm")
                }
              >
                Confirm
              </Button>
            )}
            {row?.status === "ORDERPLACED" && (
              <Button
                className="ml-2 order_cancel"
                onClick={() =>
                  this.handleOrderConfirmation(row?.order_number, "cancel")
                }
              >
                Cancel
              </Button>
            )}
            {row?.status === "ORDERCONFIRMED" && (
              <Button
                className="ml-2 order_dispatched"
                onClick={() =>
                  this.handleOrderConfirmation(row?.order_number, "dispatch")
                }
              >
                Dispatched
              </Button>
            )}
            {row?.status === "ORDERDISPATCHED" && (
              <Button
                className="ml-2 order_deliver"
                onClick={() =>
                  this.handleOrderConfirmation(row?.order_number, "deliver")
                }
              >
                Order Deliver
              </Button>
            )}
          </div>
        ),
      },
    ],
  };

  componentDidMount() {
    let token = localStorage.getItem("authToken") || "";
    API.get(`/all-orders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const currentProductVariation = response?.data;
      this.setState({ rows: currentProductVariation });
    });
  }

  handleOrderConfirmation = (id, status) => {
    let formData = {
      order_number: id,
      status: status,
    };

    let token = localStorage.getItem("authToken") || "";
    API.post(`/change-order-status`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          alert(response?.data);
        }
      })
      .then(() => {
        API.get(`/all-orders`, {
          headers: { Authorization: `Bearer ${token}` },
        }).then((response) => {
          const currentProductVariation = response?.data;

          this.setState({ rows: currentProductVariation });
        });
      })
      .catch((err) => console.log(err));
  };

  onSelect = (dateValue) => {
    if (dateValue.length > 1) {
      let formMonth = dateValue[0].getMonth() + 1;
      let toMonth = dateValue[1].getMonth() + 1;
      let fromDate = dateValue[0].getDate();
      let toDate = dateValue[1].getDate();

      if (formMonth < 10) {
        formMonth = "0" + formMonth;
      }
      if (toMonth < 10) {
        toMonth = "0" + toMonth;
      }
      if (fromDate < 10) {
        fromDate = "0" + fromDate;
      }
      if (toDate < 10) {
        toDate = "0" + toDate;
      }

      let from = dateValue[0].getFullYear() + "-" + formMonth + "-" + fromDate;
      let to = dateValue[1].getFullYear() + "-" + toMonth + "-" + toDate;

      this.setState({ dateFrom: from, dateTo: to });
    }
  };

  render() {
    let { rows, columns } = this.state;
    return (
      <div>
        <Card>
          <OrderDetailModal
            show={this.state.show}
            onHide={() => {
              this.setState({ show: false });
            }}
            review={this.state.selectReview}
          />
          {/* <Box marginBottom={2} margin={2}>
            <div className="d-flex align-items-center" style={{ columnGap: "20px" }}>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "20%" }}
              >
                <Flatpickr
                  value={this.state.date}
                  mode="range"
                  options={{
                    dateFormat: "Y-m-d",
                    mode: "range"
                  }}
                  onChange={(date) => {
                    this.onSelect(date)
                    this.setState({ date });
                  }}
                  placeholder={"Select Date Here"}
                />
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SearchOutlined />}
              >
                Search
              </Button>
            </div>
          </Box> */}
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Orders List</CardTitle>
          </CardHeader>
          <CardBody>
            <DataTableExtensions
              data={rows}
              columns={columns}
              filter={true}
              filterPlaceholder="Search..."
              exportHeaders={true}
              print={true}
            >
              <DataTable
                noHeader
                pagination
                highlightOnHover
                responsive
                customStyles={customStyles}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default OrderList;
