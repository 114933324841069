import React, { Component } from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import API from "langapi/http";
import { Avatar } from "@material-ui/core";
import { VisibilityOutlined } from "@material-ui/icons";
import OrderDetailModal from "./ProductReportModal";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "15px", // override the cell padding for head cells
      paddingRight: "15px",
      fontWeight: "600",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px", // override the cell padding for data cells
      paddingRight: "15px",
      textTransform: "Capitalize",
    },
  },
};

class ProductReport extends Component {
  state = {
    rows: [],
    show: false,
    selectReview: "",
    columns: [
      {
        name: "Image",
        selector: "featured_img",
        sortable: true,
        minWidth: "100px",
        cell: (row) => <Avatar alt="image" src={row?.featured_img}></Avatar>,
      },
      {
        name: "Product Name",
        selector: "product_detail",
        sortable: true,
        cell: (row) => (
          <p className="text-bold-500 mb-0"> {row?.product_detail?.name} </p>
        ),
      },
      {
        name: "Price",
        selector: "price",
        sortable: true,
      },
      {
        name: "Qunatity Sold",
        selector: "qty",
        sortable: true,
      },
      {
        name: "Amount",
        selector: "total",
        sortable: true,
      },
      {
        name: "Actions",
        selector: "id",
        sortable: true,
        cell: (row) => (
          <VisibilityOutlined
            fontSize="small"
            className="action-icon-delete"
            onClick={() => {
              this.setState({ show: true });
              this.setState({ selectReview: row?.id });
            }}
          />
        ),
      },
    ],
  };

  componentDidMount() {
    let token = localStorage.getItem("authToken") || "";
    API.get(`/product-report-list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      let rows = response?.data?.productData;
      this.setState({ rows });
    });
  }

  render() {
    let { rows, columns } = this.state;
    return (
      <div>
        <Card>
          <OrderDetailModal
            show={this.state.show}
            onHide={() => {
              this.setState({ show: false });
            }}
            review={this.state.selectReview}
          />
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Product Report List</CardTitle>
          </CardHeader>
          <CardBody>
            <DataTableExtensions
              data={rows}
              columns={columns}
              filter={true}
              filterPlaceholder="Search..."
              exportHeaders={true}
              print={true}
            >
              <DataTable
                noHeader
                pagination
                highlightOnHover
                responsive
                customStyles={customStyles}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default ProductReport;
