/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import {
  AttachFileRounded,
  ImageRounded,
  HotelOutlined,
  SupervisedUserCircle,
  FormatQuote,
  Code,
  CardGiftcardOutlined,
  ViewCarouselOutlined,
  InsertDriveFileOutlined,
  PostAddOutlined,
  TrackChanges,
  AddShoppingCart,
  Reorder,
  ViewList,
  QuestionAnswerRounded,
  PostAdd,
} from "@material-ui/icons";

import Orders from "views/Orders/List";
import ProductReport from "views/ProductReport/List";
import Transactions from "views/Transactions/List";
import SubscriberList from "views/subscriber/List";
import ContactsList from "views/contacts/List";
import Footer from "views/Footer/Add";
import UpdateHeader from "views/Header/Add";
import AddAboutUs from "views/SitePages/Pages/About/Add";
import AddGalleryPage from "views/SitePages/Pages/Gallery/Add";
import FAQPage from "views/SitePages/Pages/FAQ/Add";
import GalleryList from "views/Gallery/List";
import AddGallery from "views/Gallery/Add";
import SignInSide from "views/Auth/Login";
import AddContactUs from "views/SitePages/Pages/Contact/Add";
import SalesList from "views/Sales/List";
import UsersList from "views/Users/List";
import TestimonialList from "views/Testimonial/List";
import AddTestimonial from "views/Testimonial/Add";

// ------------Esme components---------
import AddBlogCategories from "views/BlogCategories/Add";
import BlogCategoriesList from "views/BlogCategories/List";

import BlogsList from "views/Blogs/List";
import BlogDetail from "views/Blogs/Details";
import AddBlog from "views/Blogs/Add";

import AddMediaCategories from "views/MediaCategories/Add";
import MediaCategoriesList from "views/MediaCategories/List";

import MediaList from "views/Media/List";
import MediaDetail from "views/Media/Details";
import AddMedia from "views/Media/Add";

import AddTeam from "views/OurTeam/Add";
import TeamList from "views/OurTeam/List";

import AddIngredient from "views/Ingredient/Add";
import IngredientList from "views/Ingredient/List";

import ProductsList from "views/ProductsList/List";
import AddProducts from "views/ProductsList/Add";

import PromoCodes from "views/PromoCodes/List";
import AddPromoCode from "views/PromoCodes/Add";

import FaqsList from "views/Faqs/List";
import AddFaqs from "views/Faqs/Add";

import PageAdd from "views/SitePages/Add";
import PageDetail from "views/SitePages/Details";
import PageList from "views/SitePages/List";

// --------Esme Static Pages------------------

import Home from "views/SitePages/Pages/Home/Add";
import SkinBeauty from "views/SitePages/Pages/SkinBeauty/Add";
import Immunity from "views/SitePages/Pages/Immunity/Add";
import AdviceOnTheUsage from "views/SitePages/Pages/AdviceOnTheUsage/Add";
import SelectionOfIngredients from "views/SitePages/Pages/SelectionOfIngredients/Add";
import ClinicalStudies from "views/SitePages/Pages/ClinicalStudies/Add";
import UniquenessOfOurFormula from "views/SitePages/Pages/UniquenessOfOurFormula/Add";
import Sustainability from "views/SitePages/Pages/Sustainability/Add";
import Packaging from "views/SitePages/Pages/Packaging/Add";
import PatentedMarineCollagenPeptides from "views/SitePages/Pages/PatentedMarineCollagenPeptides/Add";
import WhoWeAre from "views/SitePages/Pages/WhoWeAre/Add";
import CelebrateYourEssence from "views/SitePages/Pages/CelebrateYourEssence/Add";
import MeetTheCreator from "views/SitePages/Pages/MeetTheCreator/Add";
import LoyaltyProgram from "views/SitePages/Pages/LoyaltyProgram/Add";
import PrivacyPolicy from "views/SitePages/Pages/PrivacyPolicy/Add";
import TermsConditions from "views/SitePages/Pages/TermsConditions/Add";
import ReturnPolicy from "views/SitePages/Pages/ReturnPolicy/Add";
import RefundPolicy from "views/SitePages/Pages/RefundPolicy/Add";
import Shipment from "views/SitePages/Pages/Shipment/Add";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/gallery",
    name: "Gallery",
    rtlName: "ملف تعريفي للمستخدم",
    icon: ViewCarouselOutlined,
    component: GalleryList,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/pages",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: InsertDriveFileOutlined,
    component: PageList,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/pages/add",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: PageAdd,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/products",
    name: "Products",
    rtlName: "ملف تعريفي للمستخدم",
    icon: PostAdd,
    component: ProductsList,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/products/add",
    name: "Products",
    rtlName: "ملف تعريفي للمستخدم",
    icon: PostAdd,
    component: AddProducts,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/products/edit/:id",
    name: "Products",
    rtlName: "ملف تعريفي للمستخدم",
    icon: PostAdd,
    component: AddProducts,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/ingredient",
    name: "Ingredients",
    rtlName: "ملف تعريفي للمستخدم",
    icon: ViewList,
    component: IngredientList,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/ingredient/add",
    name: "Ingredients",
    rtlName: "ملف تعريفي للمستخدم",
    icon: ViewList,
    component: AddIngredient,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/ingredient/edit/:id",
    name: "Ingredients",
    rtlName: "ملف تعريفي للمستخدم",
    icon: ViewList,
    component: AddIngredient,
    layout: "/admin",
    exact: true,
    hide: true,
  },

  {
    path: "/orders",
    name: "Orders",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Reorder,
    component: Orders,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/sales",
    name: "Sales",
    rtlName: "ملف تعريفي للمستخدم",
    icon: AddShoppingCart,
    component: SalesList,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/product-report",
    name: "Product Report",
    rtlName: "ملف تعريفي للمستخدم",
    icon: HotelOutlined,
    component: ProductReport,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/transactions",
    name: "Transactions",
    rtlName: "ملف تعريفي للمستخدم",
    icon: TrackChanges,
    component: Transactions,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/promo-codes",
    name: "Promo Codes",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Code,
    component: PromoCodes,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/promo-code/add",
    name: "Promo Codes",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Code,
    component: AddPromoCode,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/promo-code/edit/:id",
    name: "Promo Codes",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Code,
    component: AddPromoCode,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "ملف تعريفي للمستخدم",
    icon: SupervisedUserCircle,
    component: UsersList,
    layout: "/admin",
    exact: true,
  },

  {
    path: "/team",
    name: "Team",
    rtlName: "ملف تعريفي للمستخدم",
    icon: SupervisedUserCircle,
    component: TeamList,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/team/add",
    name: "Team",
    rtlName: "ملف تعريفي للمستخدم",
    icon: SupervisedUserCircle,
    component: AddTeam,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/team/edit/:id",
    name: "Team",
    rtlName: "ملف تعريفي للمستخدم",
    icon: SupervisedUserCircle,
    component: AddTeam,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/testimonial",
    name: "Testimonial",
    rtlName: "ملف تعريفي للمستخدم",
    icon: FormatQuote,
    component: TestimonialList,
    layout: "/admin",
    exact: true,
  },

  {
    path: "/testimonial/add",
    name: "Testimonial",
    rtlName: "ملف تعريفي للمستخدم",
    icon: FormatQuote,
    component: AddTestimonial,
    layout: "/admin",
    exact: true,
    hide: true,
  },

  {
    path: "/testimonial/edit/:id",
    name: "Testimonial",
    rtlName: "ملف تعريفي للمستخدم",
    icon: FormatQuote,
    component: AddTestimonial,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/blogs",
    name: "Blogs",
    rtlName: "ملف تعريفي للمستخدم",
    icon: AttachFileRounded,
    component: BlogsList,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/blogs/add",
    component: AddBlog,
    layout: "/admin",
    hide: true,
    exact: true,
  },
  {
    path: "/blogs/edit/:id",
    component: AddBlog,
    layout: "/admin",
    hide: true,
    exact: true,
  },
  {
    path: "/blogs/:id",
    component: BlogDetail,
    layout: "/admin",
    hide: true,
    exact: true,
  },
  {
    path: "/blog-categories",
    name: "Blog Categories",
    rtlName: "ملف تعريفي للمستخدم",
    icon: AttachFileRounded,
    component: BlogCategoriesList,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/blog-categories/add",
    component: AddBlogCategories,
    layout: "/admin",
    hide: true,
    exact: true,
  },
  {
    path: "/blog-categories/edit/:id",
    component: AddBlogCategories,
    layout: "/admin",
    hide: true,
    exact: true,
  },
  {
    path: "/medias",
    name: "Media",
    rtlName: "ملف تعريفي",
    icon: ImageRounded,
    component: MediaList,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/medias/add",
    component: AddMedia,
    layout: "/admin",
    hide: true,
    exact: true,
  },
  {
    path: "/medias/edit/:id",
    component: AddMedia,
    layout: "/admin",
    hide: true,
    exact: true,
  },
  {
    path: "/medias/:id",
    component: MediaDetail,
    layout: "/admin",
    hide: true,
    exact: true,
  },
  {
    path: "/media-categories",
    name: "Media Categories",
    rtlName: "ملفيفي",
    icon: ImageRounded,
    component: MediaCategoriesList,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/media-categories/add",
    component: AddMediaCategories,
    layout: "/admin",
    hide: true,
    exact: true,
  },
  {
    path: "/media-categories/edit/:id",
    component: AddMediaCategories,
    layout: "/admin",
    hide: true,
    exact: true,
  },
  {
    path: "/pages/gallery/add/:id",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: AddGalleryPage,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/faq",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: FAQPage,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/about",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: AddAboutUs,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/contact",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: AddContactUs,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/home",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: Home,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/skin-beauty",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: SkinBeauty,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/immunity",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: Immunity,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/usage-advice",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: AdviceOnTheUsage,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/ingredients",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: SelectionOfIngredients,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/science",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: ClinicalStudies,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/unique-formula",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: UniquenessOfOurFormula,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/sustainability",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: Sustainability,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/packaging",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: Packaging,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/collagen-peptides",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: PatentedMarineCollagenPeptides,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/who-we-are",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: WhoWeAre,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/your-essence",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: CelebrateYourEssence,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/meet-the-creator",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: MeetTheCreator,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/loyalty-program",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: LoyaltyProgram,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/privacy-policy",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: PrivacyPolicy,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/terms-conditions",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: TermsConditions,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/return-policy",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: ReturnPolicy,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/refund-policy",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: RefundPolicy,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/shipment",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: Shipment,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/pages/:id",
    name: "Pages",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CardGiftcardOutlined,
    component: PageDetail,
    layout: "/admin",
    exact: true,
    hide: true,
  },

  {
    path: "/gallery/add",
    name: "Gallery",
    rtlName: "ملف تعريفي للمستخدم",
    icon: ViewCarouselOutlined,
    component: AddGallery,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/gallery/:id",
    name: "Gallery",
    rtlName: "ملف تعريفي للمستخدم",
    icon: ViewCarouselOutlined,
    component: GalleryList,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  // {
  //   path: "/gallery",
  //   name: "Gallery",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: ViewCarouselOutlined,
  //   component: DashboardPage,
  //   layout: "/admin",
  //   exact: true
  // },
  // {
  //   path: "/header",
  //   name: "Main Header",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: ArtTrackOutlined,
  //   component: UpdateHeader,
  //   layout: "/admin",
  //   exact: true
  // },
  // {
  //   path: "/footer",
  //   name: "Main Footer",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: HorizontalSplitOutlined,
  //   component: Footer,
  //   layout: "/admin",
  //   exact: true
  // },
  {
    path: "/posts",
    name: "Posts",
    rtlName: "ملف تعريفي للمستخدم",
    icon: PostAddOutlined,
    component: UpdateHeader,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/faq",
    name: "F.A.Q's",
    rtlName: "ملف تعريفي للمستخدم",
    icon: QuestionAnswerRounded,
    component: FaqsList,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/faqs/add",
    name: "F.A.Q's",
    rtlName: "ملف تعريفي للمستخدم",
    icon: QuestionAnswerRounded,
    component: AddFaqs,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/faqs/edit/:id",
    name: "F.A.Q's",
    rtlName: "ملف تعريفي للمستخدم",
    icon: QuestionAnswerRounded,
    component: AddFaqs,
    layout: "/admin",
    exact: true,
    hide: true,
  },
  {
    path: "/contacts",
    name: "Loyalty Program",
    rtlName: "ملف تعريفي للمستخدم",
    icon: ViewList,
    component: ContactsList,
    layout: "/admin",
    exact: true,
  },
  {
    path: "/subscriber",
    name: "Subscriber",
    rtlName: "ملف تعريفي للمستخدم",
    icon: ViewList,
    component: SubscriberList,
    layout: "/admin",
    exact: true,
  },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: UserProfile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   rtlName: "قائمة الجدول",
  //   icon: "content_paste",
  //   component: TableList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin"
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL Support",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Language,
  //   component: RTLPage,
  //   layout: "/rtl"
  // },
  // {
  //   path: "/upgrade-to-pro",
  //   name: "Upgrade To PRO",
  //   rtlName: "التطور للاحترافية",
  //   icon: Unarchive,
  //   component: UpgradeToPro,
  //   layout: "/admin"
  // }
];

export default dashboardRoutes;
