import React, { Fragment, useEffect, useState } from "react"; //Suspense
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import MaterialButton from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import { Image } from "@material-ui/icons";
import API from "langapi/http";
import { useParams, withRouter, useLocation } from "react-router-dom";
import GalleryDialog from "views/Common/GalleryDialog";
import CKEditor from "ckeditor4-react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default withRouter(function AddIngredient(props) {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const lang = query.get("lang");
  const classes = useStyles();
  let { id } = useParams();

  const initialObject = {
    title: "",
    description: "",
    featured_img: "",
    hover_img: "",
    route: "",
    lang: "",
  };

  const [ingredient, setIngredient] = useState({ ...initialObject });
  const [isEdit, setIsEdit] = useState(false);
  const [imagesData, setImagesData] = useState([]);
  const [showGallery, setShowGallery] = useState(false);
  const [isSingle, setIsSingle] = useState(false);
  const [isHoverImg, setIsHoverImg] = useState(false);
  const [selectedLang, setSelectedLang] = useState(lang || "en");

  useEffect(() => {
    if (id && id != null) {
      setIsEdit(true);
      API.get(`/ingredient/${id}?lang=${selectedLang}`).then((response) => {
        if (response.status === 200) {
          let data = { ...response?.data };
          if (response?.data) {
            setIngredient({ ...ingredient, ...data });
          } else {
            setIngredient({ ...initialObject });
          }
        }
      });
    }
    if (!imagesData.length > 0) {
      getGalleryImages();
    }
  }, [selectedLang]);

  const getGalleryImages = () => {
    API.get(`/uploads`)
      .then((response) => {
        if (response.status === 200) {
          setImagesData(
            response.data?.data?.map((x) => ({ ...x, isChecked: false }))
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const handleInputChange = (e) => {
    let updatedIngredient = { ...ingredient };
    updatedIngredient[e.target.name] = e.target.value;
    setIngredient(updatedIngredient);
  };

  const handleRouteChange = (e) => {
    let updatedIngredient = { ...ingredient };
    let updatedValue = e.target.value.replace(/\s+/g, "-");
    updatedValue = updatedValue.replace(/--/g, "-");
    updatedIngredient[e.target.name] = updatedValue.toLowerCase();
    setIngredient(updatedIngredient);
  };

  const handleImageSelect = (e, index) => {
    if (e.target.checked) {
      if (isSingle && !isHoverImg) {
        setIngredient({
          ...ingredient,
          featured_img: imagesData[index].avatar,
        });
        setTimeout(() => {
          setShowGallery(false);
        }, 500);
      } else if (isHoverImg && !isSingle) {
        setIngredient({
          ...ingredient,
          hover_img: imagesData[index].avatar,
        });
        setTimeout(() => {
          setShowGallery(false);
        }, 500);
      } else {
        setIngredient({ ...ingredient, gif: imagesData[index].avatar });
        setTimeout(() => {
          setShowGallery(false);
        }, 500);
      }
    }
  };

  const handleSubmit = () => {
    let lang = selectedLang;
    let finalIngredient = { ...ingredient, lang };

    if (!finalIngredient.title || finalIngredient.title == "") {
      alert("Please Enter title before Submiting");
      return false;
    }
    if (!finalIngredient.description || finalIngredient.description == "") {
      alert("Please Enter Description before Submiting");
      return false;
    }
    if (!finalIngredient.route || finalIngredient.route == "") {
      alert("Please Enter route before Submiting");
      return false;
    }
    if (!finalIngredient.featured_img || finalIngredient.featured_img == "") {
      alert("Please Select Featured Image before Submiting");
      return false;
    }

    let token = localStorage.getItem("authToken") || "";
    API.post(`/ingredient?lang=${selectedLang}`, finalIngredient, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          alert("Record Added");
          setIngredient({ ...initialObject });
          props.history.push(`/admin/ingredient?lang=${selectedLang}`);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChange = (event) => {
    if (event.target.value != selectedLang) {
      setSelectedLang(event.target.value);
    }
  };

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader
          color="primary"
          className="d-flex justify-content-between align-items-center"
        >
          <h4 style={{ fontWeight: "400" }} className="mb-0">
            {isEdit ? "Edit" : "Add"} Ingredient
          </h4>
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: "20%", color: "white" }}
          >
            <InputLabel id="lang" style={{ color: "white" }}>
              Select Language
            </InputLabel>
            <Select
              labelId="lang"
              id="lang"
              name="lang"
              value={selectedLang}
              label="Select Language"
              fullWidth
              style={{ color: "white" }}
              onChange={handleChange}
            >
              <MenuItem value={"en"}>En</MenuItem>
              <MenuItem value={"ar"}>AR</MenuItem>
            </Select>
          </FormControl>
        </CardHeader>
        <CardBody>
          <h4 style={{ fontWeight: "400" }} className="mt-3">
            General Information
          </h4>
          <Grid container spacing={2} style={{ display: "flex" }}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="title"
                    name="title"
                    label="Title"
                    value={ingredient.title}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    size="small"
                    helperText={""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="route"
                    name="route"
                    label="Route"
                    value={ingredient.route}
                    variant="outlined"
                    fullWidth
                    onChange={handleRouteChange}
                    size="small"
                    helperText={""}
                    disabled={isEdit ? true : false}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <h4 style={{ fontWeight: "400" }}>Detailed Content</h4>
                  <CKEditor
                    onBeforeLoad={(CKEDITOR) =>
                      (CKEDITOR.disableAutoInline = true)
                    }
                    data={ingredient.description}
                    onChange={(e) =>
                      setIngredient({
                        ...ingredient,
                        description: e.editor.getData(),
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="thumbnail-preview-wrapper img-thumbnail">
                    {!isEdit ? (
                      ingredient.featured_img &&
                      ingredient.featured_img !== "" ? (
                        <img
                          src={ingredient.featured_img}
                          alt={ingredient.alt_text || ""}
                        />
                      ) : (
                        <img
                          src={require("./../../assets/img/placeholder.png")}
                          alt=""
                        />
                      )
                    ) : typeof ingredient.featured_img === typeof 0 ? (
                      // ingredient.thumbnail && ingredient.thumbnail !== "" ?
                      <img
                        src={ingredient.featured_img}
                        alt={ingredient.alt_text || ""}
                      />
                    ) : (
                      <img
                        src={ingredient.featured_img}
                        alt={ingredient.alt_text || ""}
                      />
                    )}
                  </div>
                  <Fragment>
                    <MaterialButton
                      variant="outlined"
                      color="primary"
                      startIcon={<Image />}
                      className="mt-1"
                      fullWidth
                      onClick={() => {
                        setIsSingle(true);
                        setIsHoverImg(false);
                        setShowGallery(true);
                      }}
                    >
                      {isEdit ? "Change" : "Upload"} Featured Image
                    </MaterialButton>
                  </Fragment>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="thumbnail-preview-wrapper img-thumbnail">
                    {!isEdit ? (
                      ingredient.hover_img && ingredient.hover_img !== "" ? (
                        <img
                          src={ingredient.hover_img}
                          alt={ingredient.alt_text || ""}
                        />
                      ) : (
                        <img
                          src={require("./../../assets/img/placeholder.png")}
                          alt=""
                        />
                      )
                    ) : typeof ingredient.hover_img === typeof 0 ? (
                      // ingredient.thumbnail && ingredient.thumbnail !== "" ?
                      <img
                        src={ingredient.hover_img}
                        alt={ingredient.alt_text || ""}
                      />
                    ) : (
                      <img
                        src={ingredient.hover_img}
                        alt={ingredient.alt_text || ""}
                      />
                    )}
                  </div>
                  <Fragment>
                    <MaterialButton
                      variant="outlined"
                      color="primary"
                      startIcon={<Image />}
                      className="mt-1"
                      fullWidth
                      onClick={() => {
                        setIsSingle(false);
                        setIsHoverImg(true);
                        setShowGallery(true);
                      }}
                    >
                      {isEdit ? "Change" : "Upload"} Featured Hover Image
                    </MaterialButton>
                  </Fragment>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}></Grid>
            </Grid>

            <div className="clearfix clear-fix"></div>
            {/* GALLERY DIALOG BOX START */}

            <GalleryDialog
              open={showGallery}
              handleImageSelect={handleImageSelect}
              handleClose={() => {
                setShowGallery(false);
              }}
              refreshGallery={getGalleryImages}
              data={imagesData}
            />
            {/* GALLERY DIALOG BOX END */}
          </Grid>
        </CardBody>
      </Card>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <MaterialButton
            onClick={handleSubmit}
            style={{ float: "right" }}
            variant="contained"
            color="primary"
            size="large"
          >
            Submit
          </MaterialButton>
        </Grid>
      </Grid>
    </div>
  );
});
