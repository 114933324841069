import React, { Fragment, useEffect, useState } from "react"; //Suspense
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import MaterialButton from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import { Image } from "@material-ui/icons";
import API from "langapi/http";
import { useParams, withRouter, useLocation } from "react-router-dom";
import GalleryDialog from "views/Common/GalleryDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default withRouter(function AddTeam(props) {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const lang = query.get("lang");
  const classes = useStyles();
  let { id } = useParams();

  const initialObject = {
    name: "",
    designation: "",
    image: "",
    route: "",
    lang: "",
  };

  const [team, setTeam] = useState({ ...initialObject });
  const [isEdit, setIsEdit] = useState(false);
  const [imagesData, setImagesData] = useState([]);
  const [showGallery, setShowGallery] = useState(false);
  const [isSingle, setIsSingle] = useState(false);
  const [isAuthorImg, setIsAuthorImg] = useState(false);
  const [selectedLang, setSelectedLang] = useState(lang || "en");

  useEffect(() => {
    if (id && id != null) {
      setIsEdit(true);
      API.get(`/teams/${id}?lang=${selectedLang}`).then((response) => {
        if (response.status === 200) {
          let data = { ...response?.data };
          if (response?.data) {
            setTeam({ ...team, ...data });
          } else {
            setTeam({ ...initialObject });
          }
        }
      });
    }
    if (!imagesData.length > 0) {
      getGalleryImages();
    }
  }, [selectedLang]);

  const getGalleryImages = () => {
    API.get(`/uploads`)
      .then((response) => {
        if (response.status === 200) {
          setImagesData(
            response.data?.data?.map((x) => ({ ...x, isChecked: false }))
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const handleInputChange = (e) => {
    let updatedTeam = { ...team };
    updatedTeam[e.target.name] = e.target.value;
    setTeam(updatedTeam);
  };

  const handleRouteChange = (e) => {
    let updatedTeam = { ...team };
    let updatedValue = e.target.value.replace(/\s+/g, "-");
    updatedValue = updatedValue.replace(/--/g, "-");
    updatedTeam[e.target.name] = updatedValue.toLowerCase();
    setTeam(updatedTeam);
  };

  const handleImageSelect = (e, index) => {
    if (e.target.checked) {
      if (isSingle && !isAuthorImg) {
        setTeam({ ...team, image: imagesData[index].avatar });
        setTimeout(() => {
          setShowGallery(false);
        }, 500);
      } else {
        setTeam({ ...team, gif: imagesData[index].avatar });
        setTimeout(() => {
          setShowGallery(false);
        }, 500);
      }
    }
  };

  const handleSubmit = () => {
    let lang = selectedLang;
    let finalTeam = { ...team, lang };

    if (!finalTeam.name || finalTeam.name == "") {
      alert("Please Enter name before Submiting");
      return false;
    }
    if (!finalTeam.designation || finalTeam.designation == "") {
      alert("Please Enter Designation before Submiting");
      return false;
    }
    if (!finalTeam.route || finalTeam.route == "") {
      alert("Please Enter route before Submiting");
      return false;
    }
    if (!finalTeam.image || finalTeam.image == "") {
      alert("Please Select Featured Image before Submiting");
      return false;
    }

    let token = localStorage.getItem("authToken") || "";
    API.post(`/teams?lang=${selectedLang}`, finalTeam, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          alert("Record Added");
          setTeam({ ...initialObject });
          props.history.push(`/admin/team?lang=${selectedLang}`);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChange = (event) => {
    if (event.target.value != selectedLang) {
      setSelectedLang(event.target.value);
    }
  };

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader
          color="primary"
          className="d-flex justify-content-between align-items-center"
        >
          <h4 style={{ fontWeight: "400" }} className="mb-0">
            {isEdit ? "Edit" : "Add"} Team
          </h4>
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: "20%", color: "white" }}
          >
            <InputLabel id="lang" style={{ color: "white" }}>
              Select Language
            </InputLabel>
            <Select
              labelId="lang"
              id="lang"
              name="lang"
              value={selectedLang}
              label="Select Language"
              fullWidth
              style={{ color: "white" }}
              onChange={handleChange}
            >
              <MenuItem value={"en"}>En</MenuItem>
              <MenuItem value={"ar"}>AR</MenuItem>
            </Select>
          </FormControl>
        </CardHeader>
        <CardBody>
          <h4 style={{ fontWeight: "400" }} className="mt-3">
            General Information
          </h4>
          <Grid container spacing={2} style={{ display: "flex" }}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Name"
                    value={team.name}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    size="small"
                    helperText={""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="designation"
                    name="designation"
                    label="Designation"
                    value={team.designation}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    size="small"
                    helperText={""}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="route"
                    name="route"
                    label="Route"
                    value={team.route}
                    variant="outlined"
                    fullWidth
                    onChange={handleRouteChange}
                    size="small"
                    helperText={""}
                    disabled={isEdit ? true : false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="thumbnail-preview-wrapper img-thumbnail">
                    {!isEdit ? (
                      team.image && team.image !== "" ? (
                        <img src={team.image} alt={team.alt_text || ""} />
                      ) : (
                        <img
                          src={require("./../../assets/img/placeholder.png")}
                          alt=""
                        />
                      )
                    ) : typeof team.image === typeof 0 ? (
                      // team.thumbnail && team.thumbnail !== "" ?
                      <img src={team.image} alt={team.alt_text || ""} />
                    ) : (
                      <img src={team.image} alt={team.alt_text || ""} />
                    )}
                  </div>
                  <Fragment>
                    <MaterialButton
                      variant="outlined"
                      color="primary"
                      startIcon={<Image />}
                      className="mt-1"
                      fullWidth
                      onClick={() => {
                        setIsSingle(true);
                        setIsAuthorImg(false);
                        setShowGallery(true);
                      }}
                    >
                      {isEdit ? "Change" : "Upload"} Featured Image
                    </MaterialButton>
                  </Fragment>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}></Grid>
            </Grid>

            <div className="clearfix clear-fix"></div>
            {/* GALLERY DIALOG BOX START */}

            <GalleryDialog
              open={showGallery}
              handleImageSelect={handleImageSelect}
              handleClose={() => {
                setShowGallery(false);
              }}
              refreshGallery={getGalleryImages}
              data={imagesData}
            />
            {/* GALLERY DIALOG BOX END */}
          </Grid>
          <hr />
        </CardBody>
      </Card>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <MaterialButton
            onClick={handleSubmit}
            style={{ float: "right" }}
            variant="contained"
            color="primary"
            size="large"
          >
            Submit
          </MaterialButton>
        </Grid>
      </Grid>
    </div>
  );
});
