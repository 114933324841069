import React from 'react';

import image from './../../assets/img/404.gif';

export default function Error404(props) {
    return (
        <div className="error-wrapper">
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>4</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            <span className='particle'>0</span>
            {/* <div className="img-wrapper">
                <img src={image} alt="Error404" />
            </div> */}
            <div className="txt-wrapper">
                <p className="status-code">404</p>
                <p className="subtext">Oops, something went wrong!</p>
                <p className="description">The page you are looking for was moved, removed, renamed or might never have existed.</p>
                <button className="main-btn btn-dark">Homepage</button>
            </div>
        </div>
    );
}