import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Badge, Col, Row } from "reactstrap";
import MUIDataTable from "mui-datatables";
import API from "langapi/http";
import { Avatar } from "@material-ui/core";
// import "./OrderDetails.scss";

const ProductReportModal = (props) => {
  // let { user, product, rating, comments } = props.review;
  let orderId = props?.review || "";
  let data = order?.order_detail
  console.log(props, "order list")
  let columns = [
    {
      name: "variation_detail",
      label: "Image",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (val, row) => (
          <Avatar
            alt={''}
            src={val?.images[0]?.avatar}
          ></Avatar>
        ),
      },
    },
    {
      name: "order",
      label: "Order No",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => (
          < p className="text-bold-500 mb-0" > {val?.order_number} </p>
        ),
      },
    },
    {
      name: "qty",
      label: "Quantity",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => (
          < p className="text-bold-500 mb-0" > {val} </p>
        ),
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => (
          < p className="text-bold-500 mb-0" > {val} </p>
        ),
      },
    },
    {
      name: "total",
      label: "Sub Total",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => (
          < p className="text-bold-500 mb-0" > {val} </p>
        ),
      },
    },
  ]

  const [order, setOrder] = useState();

  useEffect((props) => {
    if (orderId) {
      let token = localStorage.getItem("authToken") || ""
      API.get(`/auth/product-report-detail/${orderId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => {
        setOrder(response?.data);
      });
    }
  }, [props]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modelForOrder"
    >
      <Modal.Header closeButton className="orderdetailModelheader">
        <Modal.Title id="contained-modal-title-vcenter">
          Order Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="orderdetailModelBody">
        <div>
          <Row>
            <Col sm={3}>
              <h6>Product Name:</h6>
            </Col>
            <Col>
              <p>{order?.productDetail?.name}</p>
            </Col>
            <Col sm={3}>
              <h6>Quantity Sold:</h6>
            </Col>
            <Col sm={3}>
              <p>{order?.total_purchase[0]}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <h6>Total Amount:</h6>
            </Col>
            <Col sm={3}>
              <p>{order?.total_amount[0]}</p>
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <h6>Products List:</h6>
          {/* <p>{comments}</p> */}
          <MUIDataTable
            className="dataTable-custom"
            data={order?.detail}
            columns={columns}
            noHeader
          // pagination
          // subHeader
          // subHeaderComponent={
          //   <CustomHeader
          //     value={value}
          //     handleFilter={this.handleFilter}
          //     {...this.props}
          //   />
          // }
          />

        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductReportModal;
