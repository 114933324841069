import React, { Component } from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import API from "langapi/http";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "15px", // override the cell padding for head cells
      paddingRight: "15px",
      fontWeight: "600",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px", // override the cell padding for data cells
      paddingRight: "15px",
      textTransform: "Capitalize",
    },
  },
};

class TransactionList extends Component {
  state = {
    rows: [],
    columns: [
      {
        name: "Payment Date",
        selector: "payment_date",
        sortable: true,
        minWidth: "100px",
        cell: (row) => (
          <p className="text-bold-500 mb-0">
            {new Date(row?.payment_date).toLocaleDateString()}
          </p>
        ),
      },
      {
        name: "Customer Name",
        selector: "user_detail",
        sortable: true,
        cell: (row) => (
          <p className="text-bold-500 mb-0"> {row?.user_detail?.name} </p>
        ),
      },
      {
        name: "Customer Email",
        selector: "user_detail",
        sortable: true,
        cell: (row) => (
          <p className="text-bold-500 mb-0"> {row?.user_detail?.email} </p>
        ),
      },
      {
        name: "Order No",
        selector: "order_id",
        sortable: true,
      },
      {
        name: "Amount",
        selector: "amount",
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        cell: (row) => (
          <code
            style={{
              color: `${row?.status === "1" ? "green" : "red"}`,
              fontWeight: "bold",
            }}
          >
            {row?.status == 1 ? "Captured" : "Failed"}
          </code>
        ),
      },
    ],
  };

  componentDidMount() {
    let token = localStorage.getItem("authToken") || "";
    API.get(`/transaction`, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      let rows = response?.data;
      this.setState({ rows });
    });
  }

  render() {
    let { rows, columns } = this.state;
    return (
      <div>
        <Card>
          <CardHeader>
            <CardTitle>Transactions List</CardTitle>
          </CardHeader>
          <CardBody>
            <DataTableExtensions
              data={rows}
              columns={columns}
              filter={true}
              filterPlaceholder="Search..."
              exportHeaders={true}
              print={true}
            >
              <DataTable
                noHeader
                pagination
                highlightOnHover
                responsive
                customStyles={customStyles}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default TransactionList;
