import React, { Component } from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import InputLabel from "@material-ui/core/InputLabel";
import { Box, Button, MenuItem, Select, FormControl } from "@material-ui/core";
import { Link } from "react-router-dom";
import { AddOutlined } from "@material-ui/icons";
import API from "langapi/http";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "15px", // override the cell padding for head cells
      paddingRight: "15px",
      fontWeight: "600",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px", // override the cell padding for data cells
      paddingRight: "15px",
      textTransform: "Capitalize",
    },
  },
};

class PageList extends Component {
  state = {
    selectedLang: window.location.search.split("=")[1] || "en",
    rows: [],
    columns: [
      {
        name: "Name",
        selector: "name",
        sortable: true,
      },
      {
        name: "Actions",
        selector: "route",
        sortable: true,
        cell: (row) => (
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={() =>
              this.props.history.push(
                `/admin/pages/${row?.route}?lang=${this.state.selectedLang}`
              )
            }
          >
            Update Sections
          </Button>
        ),
      },
    ],
  };

  componentDidMount() {
    API.get(`/pages?lang=${this.state.selectedLang}`)
      .then((response) => {
        let rows = response?.data;
        this.setState({ rows });
      })
      .catch((err) => console.log(err));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedLang !== this.state.selectedLang) {
      API.get(`/pages?lang=${this.state.selectedLang}`)
        .then((response) => {
          let rows = response.data;
          if (this.state.rows != rows) {
            this.setState({ rows });
          }
        })
        .catch((err) => console.log(err));
    }
  }

  handleChange = (event) => {
    if (event.target.value != this.state.selectedLang) {
      this.setState({ selectedLang: event.target.value });
    }
  };

  render() {
    let { rows, columns } = this.state;
    return (
      <div>
        <Box marginBottom={4}>
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/admin/pages/add">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddOutlined />}
              >
                Add Page
              </Button>
            </Link>
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: "20%" }}
            >
              <InputLabel id="lang">Select Language</InputLabel>
              <Select
                labelId="lang"
                id="lang"
                name="lang"
                value={this.state.selectedLang}
                label="Select Language"
                fullWidth
                onChange={this.handleChange}
              >
                <MenuItem value={"en"}>En</MenuItem>
                <MenuItem value={"ar"}>AR</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Box>
        <Card>
          <CardHeader>
            <CardTitle>Site Pages List</CardTitle>
          </CardHeader>
          <CardBody>
            <DataTableExtensions
              data={rows}
              columns={columns}
              filter={true}
              filterPlaceholder="Search..."
              exportHeaders={true}
              print={true}
            >
              <DataTable
                noHeader
                pagination
                highlightOnHover
                responsive
                customStyles={customStyles}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default PageList;
