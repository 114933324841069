import React, { Component } from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import API from "langapi/http";
import InputLabel from "@material-ui/core/InputLabel";
import { Avatar, Box, Button } from "@material-ui/core";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  AddOutlined,
  EditOutlined,
  VisibilityOutlined,
  VisibilityOffOutlined,
} from "@material-ui/icons";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "15px", // override the cell padding for head cells
      paddingRight: "15px",
      fontWeight: "600",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px", // override the cell padding for data cells
      paddingRight: "15px",
      textTransform: "Capitalize",
    },
  },
};

class ProductList extends Component {
  state = {
    selectedLang: window.location.search.split("=")[1] || "en",
    rows: [],
    columns: [
      {
        name: "Image",
        selector: "featured_img1",
        sortable: true,
        minWidth: "100px",
        cell: (row) => <Avatar alt="image" src={row?.featured_img1}></Avatar>,
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
      },
      {
        name: "Description",
        selector: "short_description",
        sortable: true,
        cell: (row) => (
          <code>
            {row?.short_description?.length > 100
              ? row?.short_description?.substr(0, 100) + "..."
              : row?.short_description}
          </code>
        ),
      },
      {
        name: "Pack of",
        selector: "pack_of",
        sortable: true,
      },
      {
        name: "Actions",
        selector: "route",
        sortable: true,
        cell: (row) => (
          <div className="d-flex nowrap">
            <EditOutlined
              fontSize="small"
              className="action-icon-edit"
              onClick={() =>
                this.props.history.push(
                  `/admin/products/edit/${row?.route}?lang=${this.state.selectedLang}`
                )
              }
            />
            <VisibilityOutlined
              fontSize="small"
              className="action-icon-delete"
              onClick={() => this.handleDisable(row?.id)}
            />
          </div>
        ),
      },
    ],
    disablerows: [],
    disablecolumns: [
      {
        name: "Image",
        selector: "featured_img1",
        sortable: true,
        minWidth: "100px",
        cell: (row) => <Avatar alt="image" src={row?.featured_img1}></Avatar>,
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
      },
      {
        name: "Description",
        selector: "short_description",
        sortable: true,
        cell: (row) => (
          <code>
            {row?.short_description?.length > 100
              ? row?.short_description?.substr(0, 100) + "..."
              : row?.short_description}
          </code>
        ),
      },
      {
        name: "Pack of",
        selector: "pack_of",
        sortable: true,
      },
      {
        name: "Actions",
        selector: "route",
        sortable: true,
        cell: (row) => (
          <div className="d-flex nowrap">
            <EditOutlined
              fontSize="small"
              className="action-icon-edit"
              onClick={() =>
                this.props.history.push(
                  `/admin/products/edit/${row?.route}?lang=${this.state.selectedLang}`
                )
              }
            />
            <VisibilityOffOutlined
              fontSize="small"
              className="action-icon-delete"
              onClick={() => this.handleDisable(row?.id)}
            />
          </div>
        ),
      },
    ],
  };

  componentDidMount() {
    API.get(`/products?lang=${this.state.selectedLang}`)
      .then((response) => {
        this.setState({
          rows: response.data.sort((a, b) => a.currentIndex - b.currentIndex),
        });
      })
      .catch((err) => console.log(err));

    API.get(`/disable-products?lang=${this.state.selectedLang}`)
      .then((response) => {
        let disablerows = response.data.sort(
          (a, b) => a.currentIndex - b.currentIndex
        );
        this.setState({ disablerows });
      })
      .catch((err) => console.log(err));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedLang !== this.state.selectedLang) {
      API.get(`/products?lang=${this.state.selectedLang}`)
        .then((response) => {
          let rows = response.data.sort(
            (a, b) => a.currentIndex - b.currentIndex
          );
          if (this.state.rows != rows) {
            this.setState({ rows });
          }
        })
        .catch((err) => console.log(err));
      API.get(`/disable-products?lang=${this.state.selectedLang}`)
        .then((response) => {
          let disablerows = response.data.sort(
            (a, b) => a.currentIndex - b.currentIndex
          );
          if (this.state.disablerows != disablerows) {
            this.setState({ disablerows });
          }
        })
        .catch((err) => console.log(err));
    }
  }

  handleDisable = (id) => {
    if (window.confirm("Are you sure you want to Disable this ?")) {
      let token = localStorage.getItem("authToken") || "";
      API.put(`/change-status/${id}`, id, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          if (response.status === 200) {
            alert("Product Disable successfully!");
          }
        })
        .then(() => {
          API.get(`/products?lang=${this.state.selectedLang}`).then(
            (response) => {
              this.setState({
                rows: response.data.sort(
                  (a, b) => a.currentIndex - b.currentIndex
                ),
              });
            }
          );
          API.get(`/disable-products?lang=${this.state.selectedLang}`).then(
            (response) => {
              this.setState({
                disablerows: response.data.sort(
                  (a, b) => a.currentIndex - b.currentIndex
                ),
              });
            }
          );
        })
        .catch((err) => console.log(err));
    }
  };

  handleChange = (event) => {
    if (event.target.value != this.state.selectedLang) {
      this.setState({ selectedLang: event.target.value });
    }
  };
  render() {
    let { rows, columns, disablerows, disablecolumns } = this.state;
    return (
      <div>
        <Box marginBottom={4}>
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/admin/products/add">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddOutlined />}
              >
                Add Product
              </Button>
            </Link>
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: "20%" }}
            >
              <InputLabel id="lang">Select Language</InputLabel>
              <Select
                labelId="lang"
                id="lang"
                name="lang"
                value={this.state.selectedLang}
                label="Select Language"
                fullWidth
                onChange={this.handleChange}
              >
                <MenuItem value={"en"}>En</MenuItem>
                <MenuItem value={"ar"}>AR</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Box>
        <Card>
          <CardHeader>
            <CardTitle>Products List</CardTitle>
          </CardHeader>
          <CardBody>
            <DataTableExtensions
              data={rows}
              columns={columns}
              filter={true}
              filterPlaceholder="Search..."
              exportHeaders={true}
              print={true}
            >
              <DataTable
                noHeader
                pagination
                highlightOnHover
                responsive
                customStyles={customStyles}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>

        <div style={{ marginTop: "60px" }}>
          <Card>
            <CardHeader>
              <CardTitle>Disabled Products List</CardTitle>
            </CardHeader>
            <CardBody>
              <DataTableExtensions
                data={disablerows}
                columns={disablecolumns}
                filter={true}
                filterPlaceholder="Search..."
                exportHeaders={true}
                print={true}
              >
                <DataTable
                  noHeader
                  pagination
                  highlightOnHover
                  responsive
                  customStyles={customStyles}
                />
              </DataTableExtensions>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default ProductList;
