import React, { Component } from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import API from "langapi/http";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "15px", // override the cell padding for head cells
      paddingRight: "15px",
      fontWeight: "600",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px", // override the cell padding for data cells
      paddingRight: "15px",
      textTransform: "Capitalize",
    },
  },
};

class SalesList extends Component {
  state = {
    rows: [],
    date: "",
    dateFrom: "",
    dateTo: "",
    show: false,
    columns: [
      {
        name: "Date",
        selector: "created_at",
        sortable: true,
        cell: (row) => (
          <p className="text-bold-500 mb-0">
            {new Date(row?.created_at).toLocaleDateString()}{" "}
          </p>
        ),
      },
      {
        name: "Customer Name",
        selector: "user_detail",
        sortable: true,
        cell: (row) => (
          <p className="text-bold-500 mb-0">{row?.user_detail?.name}</p>
        ),
      },
      {
        name: "Sub Toal",
        selector: "sub_total",
        sortable: true,
      },
      {
        name: "Discount",
        selector: "discount",
        sortable: true,
      },
      {
        name: "Shipping Charges",
        selector: "shipping_charges",
        sortable: true,
      },
      {
        name: "Promo Code",
        selector: "coupon",
        sortable: true,
      },
      {
        name: "Total Amount",
        selector: "total",
        sortable: true,
      },
    ],
  };

  componentDidMount() {
    let token = localStorage.getItem("authToken") || "";
    API.get(`/sales-list`, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      let rows = response?.data;
      this.setState({ rows });
    });
  }

  onSelect = (dateValue) => {
    if (dateValue.length > 1) {
      let formMonth = dateValue[0].getMonth() + 1;
      let toMonth = dateValue[1].getMonth() + 1;
      let fromDate = dateValue[0].getDate();
      let toDate = dateValue[1].getDate();

      if (formMonth < 10) {
        formMonth = "0" + formMonth;
      }
      if (toMonth < 10) {
        toMonth = "0" + toMonth;
      }
      if (fromDate < 10) {
        fromDate = "0" + fromDate;
      }
      if (toDate < 10) {
        toDate = "0" + toDate;
      }

      let from = dateValue[0].getFullYear() + "-" + formMonth + "-" + fromDate;
      let to = dateValue[1].getFullYear() + "-" + toMonth + "-" + toDate;

      this.setState({ dateFrom: from, dateTo: to });
    }
  };
  render() {
    let { rows, columns } = this.state;
    return (
      <div>
        <Card>
          {/* <Box marginBottom={2} margin={2}>
            <div className="d-flex align-items-center" style={{ columnGap: "20px" }}>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "20%" }}
              // fullWidth
              >
                <Flatpickr
                  value={this.state.date}
                  mode="range"
                  options={{
                    dateFormat: "Y-m-d",
                    mode: "range"
                  }}
                  onChange={(date) => {
                    this.onSelect(date)
                    this.setState({ date });
                  }}
                  placeholder={"Select Date Here"}
                />
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SearchOutlined />}
              >
                Search
              </Button>
            </div>
          </Box> */}
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Sales List</CardTitle>
          </CardHeader>
          <CardBody>
            <DataTableExtensions
              data={rows}
              columns={columns}
              filter={true}
              filterPlaceholder="Search..."
              exportHeaders={true}
              print={true}
            >
              <DataTable
                noHeader
                pagination
                highlightOnHover
                responsive
                customStyles={customStyles}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default SalesList;
