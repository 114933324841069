import React, { Component } from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import API from "langapi/http";
import UserDetailsModal from "./UserDetailsModal";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "15px", // override the cell padding for head cells
      paddingRight: "15px",
      fontWeight: "600",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px", // override the cell padding for data cells
      paddingRight: "15px",
      textTransform: "Capitalize",
    },
  },
};

class UsersList extends Component {
  state = {
    rows: [],
    show: false,
    columns: [
      {
        name: "Name",
        selector: "name",
        sortable: true,
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
      },
      {
        name: "User Type",
        selector: "user_type",
        sortable: true,
      },
    ],
  };

  componentDidMount() {
    let token = localStorage.getItem("authToken") || "";
    API.get(`/all-users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        let rows = response?.data;
        this.setState({ rows });
      })
      .catch((err) => console.log(err));
  }

  render() {
    let { rows, columns } = this.state;
    return (
      <div>
        <UserDetailsModal
          show={this.state.show}
          onHide={() => {
            this.setState({ show: false });
          }}
          review={this.state.selectReview}
        />

        <Card>
          <CardHeader>
            <CardTitle>Users List</CardTitle>
          </CardHeader>
          <CardBody>
            <DataTableExtensions
              data={rows}
              columns={columns}
              filter={true}
              filterPlaceholder="Search..."
              exportHeaders={true}
              print={true}
            >
              <DataTable
                noHeader
                pagination
                highlightOnHover
                responsive
                customStyles={customStyles}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default UsersList;
