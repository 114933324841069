import React, { Component } from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import API from "langapi/http";
import InputLabel from "@material-ui/core/InputLabel";
import { Avatar, Box, Button } from "@material-ui/core";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { AddOutlined, DeleteOutlined, EditOutlined } from "@material-ui/icons";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "15px", // override the cell padding for head cells
      paddingRight: "15px",
      fontWeight: "600",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px", // override the cell padding for data cells
      paddingRight: "15px",
      textTransform: "Capitalize",
    },
  },
};

class TestimonialsList extends Component {
  state = {
    selectedLang: "en",
    rows: [],
    columns: [
      {
        name: "Image",
        selector: "img",
        sortable: true,
        minWidth: "100px",
        cell: (row) => <Avatar alt="image" src={row?.img}></Avatar>,
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
      },
      {
        name: "Designation",
        selector: "designation",
        sortable: true,
        cell: (row) => (
          <code>
            {row?.designation?.length > 100
              ? row?.designation?.substr(0, 100) + "..."
              : row?.designation}
          </code>
        ),
      },
      {
        name: "Actions",
        selector: "id",
        sortable: true,
        cell: (row) => (
          <div className="d-flex nowrap">
            <EditOutlined
              fontSize="small"
              className="action-icon-edit"
              onClick={() =>
                this.props.history.push(`/admin/testimonial/edit/${row?.id}`)
              }
            />
            <DeleteOutlined
              fontSize="small"
              className="action-icon-delete"
              onClick={() => this.handleDelete(row?.id)}
            />
          </div>
        ),
      },
    ],
  };

  componentDidMount() {
    API.get(`/testimonials`).then((response) => {
      let rows = response.data;
      this.setState({ rows });
      console.log("rows :: ", rows);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedLang !== this.state.selectedLang) {
      API.get(`/testimonials`).then((response) => {
        let rows = response.data;
        if (this.state.rows != rows) {
          this.setState({ rows });
        }
      });
    }
  }

  handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this ?")) {
      let token = localStorage.getItem("authToken") || "";
      API.delete(`/testimonials/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            alert("Blog deleted successfully !");
          }
        })
        .then(() => {
          API.get(`/testimonials`).then((response) => {
            let rows = response.data;
            this.setState({ rows });
          });
        })
        .catch((err) => console.log(err));
    }
  };

  handleChange = (event) => {
    // setAge(event.target.value as string);
    if (event.target.value != this.state.selectedLang) {
      this.setState({ selectedLang: event.target.value });
    }
    console.log(event.target.value, "event.target.value");
  };

  render() {
    let { rows, columns } = this.state;
    return (
      <div>
        <Box marginBottom={4}>
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/admin/testimonial/add">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddOutlined />}
              >
                Add New
              </Button>
            </Link>
            {/* <FormControl
              variant="outlined"
              size="small"
              style={{ width: "20%" }}
            >
              <InputLabel id="language">Select Language</InputLabel>
              <Select
                labelId="language"
                id="language"
                name="language"
                value={this.state.selectedLang}
                label="Select Language"
                fullWidth
                onChange={this.handleChange}
              >
                <MenuItem value={'en'}>En</MenuItem>
                <MenuItem value={'fr'}>FR</MenuItem>
                <MenuItem value={'de'}>DE</MenuItem>

              </Select>
            </FormControl> */}
          </div>
        </Box>

        <Card>
          <CardHeader>
            <CardTitle>Testimonial List</CardTitle>
          </CardHeader>
          <CardBody>
            <DataTableExtensions
              data={rows}
              columns={columns}
              filter={true}
              filterPlaceholder="Search..."
              exportHeaders={true}
              print={true}
            >
              <DataTable
                noHeader
                pagination
                highlightOnHover
                responsive
                customStyles={customStyles}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default TestimonialsList;
