import React, { Fragment, useEffect, useState } from "react"; //Suspense
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import MaterialButton from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import CKEditor from "ckeditor4-react";
import API from "langapi/http";
import { useParams, withRouter, useLocation } from "react-router-dom";
import GalleryDialog from "views/Common/GalleryDialog";
import { Image } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default withRouter(function AddMedia(props) {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const lang = query.get("lang");
  const classes = useStyles();
  let { id } = useParams();

  const initialObject = {
    title: "",
    long_description: "",
    short_description: "",
    featured_img: "",
    banner_img: "",
    route: "",
    category_id: "",
    posted_by: "",
    lang: "",
    seo: {
      meta_title: "",
      meta_description: "",
      schema_markup: "",
    },
  };

  const [media, setMedia] = useState({ ...initialObject });
  const [isEdit, setIsEdit] = useState(false);
  const [imagesData, setImagesData] = useState([]);
  const [showGallery, setShowGallery] = useState(false);
  const [isSingle, setIsSingle] = useState(false);
  const [selectedLang, setSelectedLang] = useState(lang || "en");
  const [thumbnailPreview, setThumbnailPreview] = useState("");
  const [isBanner, setIsBanner] = useState(false);
  const [bannerThumbnailPreview, setBannerThumbnailPreview] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (id && id != null) {
      setIsEdit(true);
      API.get(`/medias/${id}?lang=${selectedLang}`).then((response) => {
        if (response.status === 200) {
          let data = { ...response?.data };
          if (response?.data) {
            setMedia({ ...media, ...data });
          } else {
            setMedia({ ...initialObject });
          }
        }
      });
    }

    API.get(`/media-category?lang=${selectedLang}`).then((response) => {
      if (response.status === 200) {
        if (response?.data) {
          setCategories(response?.data);
        }
      }
    });

    if (!imagesData.length > 0) {
      getGalleryImages();
    }
  }, [selectedLang]);

  const getGalleryImages = () => {
    API.get(`/uploads`)
      .then((response) => {
        if (response.status === 200) {
          setImagesData(
            response.data?.data?.map((x) => ({ ...x, isChecked: false }))
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const handleInputChange = (e) => {
    let updatedMedia = { ...media };
    updatedMedia[e.target.name] = e.target.value;
    setMedia(updatedMedia);
  };
  const handleSEOInputChange = (e) => {
    let updatedMedia = { ...media };
    updatedMedia.seo[e.target.name] = e.target.value;
    setMedia(updatedMedia);
  };

  const handlerouteChange = (e) => {
    let updatedMedia = { ...media };
    let updatedValue = e.target.value.replace(/\s+/g, "-");
    updatedValue = updatedValue.replace(/--/g, "-");
    updatedMedia[e.target.name] = updatedValue.toLowerCase();
    setMedia(updatedMedia);
  };

  const handleImageSelect = (e, index) => {
    if (e.target.checked) {
      if (isSingle && !isBanner) {
        setMedia({ ...media, featured_img: imagesData[index].avatar });
        setThumbnailPreview(imagesData[index].avatar);
        setTimeout(() => {
          setShowGallery(false);
        }, 500);
      } else if (isBanner && !isSingle) {
        setMedia({ ...media, banner_img: imagesData[index].avatar });
        setBannerThumbnailPreview(imagesData[index].avatar);
        setTimeout(() => {
          setShowGallery(false);
        }, 500);
      }
      let imagesDataUpdated = imagesData.map((x, i) => {
        if (i === index) {
          return {
            ...x,
            isChecked: true,
          };
        } else {
          return x;
        }
      });
      setImagesData(imagesDataUpdated);
      // }
    } else {
      if (isSingle && !isBanner) {
        setMedia({ ...media, img: "" });
        setThumbnailPreview("");
      } else if (isBanner && !isSingle) {
        setMedia({ ...media, banner_img: "" });
        setBannerThumbnailPreview("");
      }
      setImagesData(
        imagesData.map((x, i) => {
          if (i === index) {
            return {
              ...x,
              isChecked: false,
            };
          } else {
            return x;
          }
        })
      );
    }
  };

  const handleSubmit = () => {
    let lang = selectedLang;
    let finalMedia = { ...media, lang };
    if (!finalMedia.title || finalMedia.title == "") {
      alert("Please Enter Name before Submiting");
      return false;
    }
    if (!finalMedia.route || finalMedia.route == "") {
      alert("Please Enter route before Submiting");
      return false;
    }
    if (!finalMedia.featured_img || finalMedia.featured_img == "") {
      alert("Please Select Featured Image before Submiting");
      return false;
    }
    if (!finalMedia.banner_img || finalMedia.banner_img == "") {
      alert("Please Select Banner Image before Submiting");
      return false;
    }
    if (!finalMedia.short_description || finalMedia.short_description == "") {
      alert("Please Enter Short Description before Submiting");
      return false;
    }
    if (!finalMedia.long_description || finalMedia.long_description == "") {
      alert("Please Enter Description before Submiting");
      return false;
    }

    let token = localStorage.getItem("authToken") || "";
    API.post(`/medias`, finalMedia, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          alert("Media Added");
          setMedia({ ...initialObject });
          props.history.push(`/admin/medias?lang=${selectedLang}`);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChange = (event) => {
    if (event.target.value != selectedLang) {
      setSelectedLang(event.target.value);
    }
  };

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader
          color="primary"
          className="d-flex justify-content-between align-items-center"
        >
          <h4 style={{ fontWeight: "400" }} className="mb-0">
            {isEdit ? "Edit" : "Add"} Media
          </h4>
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: "20%", color: "white" }}
          >
            <InputLabel id="lang" style={{ color: "white" }}>
              Select Language
            </InputLabel>
            <Select
              labelId="lang"
              id="lang"
              name="lang"
              value={selectedLang}
              label="Select Language"
              fullWidth
              style={{ color: "white" }}
              onChange={handleChange}
            >
              <MenuItem value={"en"}>En</MenuItem>
              <MenuItem value={"ar"}>AR</MenuItem>
            </Select>
          </FormControl>
        </CardHeader>
        <CardBody>
          <h4 style={{ fontWeight: "400" }} className="mt-3">
            General Information
          </h4>
          <Grid container spacing={2} style={{ display: "flex" }}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="title"
                    name="title"
                    label="Name"
                    value={media.title}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    size="small"
                    helperText={""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="posted_by"
                    name="posted_by"
                    label="Written By"
                    value={media.posted_by}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    size="small"
                    helperText={""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="route"
                    name="route"
                    label="Route"
                    value={media.route}
                    variant="outlined"
                    fullWidth
                    onChange={handlerouteChange}
                    size="small"
                    helperText={""}
                    disabled={isEdit ? true : false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    style={{ width: "100%", color: "" }}
                    // fullWidth
                  >
                    <InputLabel id="category" style={{ color: "" }}>
                      Select Category
                    </InputLabel>
                    <Select
                      labelId="category"
                      id="category"
                      name="category_id"
                      value={media?.category_id}
                      label="Select Category"
                      fullWidth
                      style={{ color: "" }}
                      onChange={handleInputChange}
                    >
                      {categories?.map((name) => (
                        <MenuItem key={name.id} value={name.id}>
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <div className="thumbnail-preview-wrapper img-thumbnail">
                    {!isEdit ? (
                      thumbnailPreview && thumbnailPreview !== "" ? (
                        <img
                          src={thumbnailPreview}
                          alt={media.alt_text || ""}
                        />
                      ) : (
                        <img
                          src={require("./../../assets/img/placeholder.png")}
                          alt=""
                        />
                      )
                    ) : typeof media.featured_img === typeof 0 ? (
                      // media.thumbnail && media.thumbnail !== "" ?
                      <img src={thumbnailPreview} alt={media.alt_text || ""} />
                    ) : (
                      <img
                        src={media.featured_img}
                        alt={media.alt_text || ""}
                      />
                    )}
                  </div>
                  <Fragment>
                    <MaterialButton
                      variant="outlined"
                      color="primary"
                      startIcon={<Image />}
                      className="mt-1"
                      fullWidth
                      onClick={() => {
                        setIsSingle(true);
                        setIsBanner(false);
                        setShowGallery(true);
                      }}
                    >
                      {isEdit ? "Change" : "Upload"} Featured Image
                    </MaterialButton>
                  </Fragment>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <div className="thumbnail-preview-wrapper img-thumbnail">
                    {!isEdit ? (
                      bannerThumbnailPreview &&
                      bannerThumbnailPreview !== "" ? (
                        <img
                          src={bannerThumbnailPreview}
                          alt={media.alt_text || ""}
                        />
                      ) : (
                        <img
                          src={require("./../../assets/img/placeholder.png")}
                          alt=""
                        />
                      )
                    ) : typeof media.banner_img === typeof 0 ? (
                      // media.thumbnail && media.thumbnail !== "" ?
                      <img
                        src={bannerThumbnailPreview}
                        alt={media.alt_text || ""}
                      />
                    ) : (
                      <img src={media.banner_img} alt={media.alt_text || ""} />
                    )}
                  </div>
                  <Fragment>
                    <MaterialButton
                      variant="outlined"
                      color="primary"
                      startIcon={<Image />}
                      className="mt-1"
                      fullWidth
                      onClick={() => {
                        setIsSingle(false);
                        setIsBanner(true);
                        setShowGallery(true);
                      }}
                    >
                      {isEdit ? "Change" : "Upload"} Banner Image
                    </MaterialButton>
                  </Fragment>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <hr />
              <h4 style={{ fontWeight: "400" }}>Short Description</h4>
              <CKEditor
                onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                data={media.short_description}
                onChange={(e) =>
                  setMedia({ ...media, short_description: e.editor.getData() })
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <hr />
              <h4 style={{ fontWeight: "400" }}>Detailed Content</h4>

              <CKEditor
                onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                data={media.long_description}
                onChange={(e) =>
                  setMedia({ ...media, long_description: e.editor.getData() })
                }
              />
            </Grid>
            <div className="clearfix clear-fix"></div>
            {/* GALLERY DIALOG BOX START */}

            <GalleryDialog
              open={showGallery}
              handleImageSelect={handleImageSelect}
              handleClose={() => {
                setShowGallery(false);
              }}
              refreshGallery={getGalleryImages}
              data={imagesData}
            />
            {/* GALLERY DIALOG BOX END */}
          </Grid>
          <hr />

          <h4 style={{ fontWeight: "400" }} className="mt-2">
            SEO Information
          </h4>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="meta_title"
                name="meta_title"
                label="Meta Title"
                value={media?.seo?.meta_title}
                variant="outlined"
                fullWidth
                onChange={handleSEOInputChange}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="meta_description"
                name="meta_description"
                label="Meta Description"
                value={media?.seo?.meta_description}
                variant="outlined"
                fullWidth
                onChange={handleSEOInputChange}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="schema_markup"
                name="schema_markup"
                label="Schema Markup"
                value={media?.seo?.schema_markup}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                rowsMax={4}
                onChange={handleSEOInputChange}
                size="small"
              />
            </Grid>
          </Grid>
        </CardBody>
      </Card>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <MaterialButton
            onClick={handleSubmit}
            style={{ float: "right" }}
            variant="contained"
            color="primary"
            size="large"
          >
            Submit
          </MaterialButton>
        </Grid>
      </Grid>
    </div>
  );
});
