import React, { Component } from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import API from "langapi/http";
import { Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { AddOutlined, DeleteOutlined, EditOutlined } from "@material-ui/icons";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "15px", // override the cell padding for head cells
      paddingRight: "15px",
      fontWeight: "600",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px", // override the cell padding for data cells
      paddingRight: "15px",
      textTransform: "Capitalize",
    },
  },
};

class PromoCodeList extends Component {
  state = {
    rows: [],
    columns: [
      {
        name: "Name",
        selector: "name",
        sortable: true,
      },
      {
        name: "Discount in %",
        selector: "value",
        sortable: true,
      },
      {
        name: "Usage Limit",
        selector: "usage",
        sortable: true,
      },
      {
        name: "Usage Count",
        selector: "code_count",
        sortable: true,
      },
      {
        name: "Start Date",
        selector: "start_date",
        sortable: true,
      },
      {
        name: "End Date",
        selector: "end_date",
        sortable: true,
      },
      {
        name: "Actions",
        selector: "id",
        sortable: true,
        cell: (row) => (
          <div className="d-flex nowrap">
            <EditOutlined
              fontSize="small"
              className="action-icon-edit"
              onClick={() =>
                this.props.history.push(`/admin/promo-code/edit/${row?.id}`)
              }
            />
            <DeleteOutlined
              fontSize="small"
              className="action-icon-delete"
              onClick={() => this.handleDelete(row?.id)}
            />
          </div>
        ),
      },
    ],
  };

  componentDidMount() {
    API.get(`/promo-codes`).then((response) => {
      let rows = response?.data;
      this.setState({ rows });
    });
  }

  handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this ?")) {
      let token = localStorage.getItem("authToken") || "";
      API.delete(`/promo-codes/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            alert("Promo Code deleted successfully !");
          }
        })
        .then(() => {
          API.get(`/promo-codes`).then((response) => {
            let rows = response?.data;
            this.setState({ rows });
          });
        })
        .catch((err) => console.log(err));
    }
  };

  render() {
    let { rows, columns } = this.state;
    return (
      <div>
        <Box marginBottom={4}>
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/admin/promo-code/add">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddOutlined />}
              >
                Create Promo Code
              </Button>
            </Link>
          </div>
        </Box>
        <Card>
          <CardHeader>
            <CardTitle>Promo Codes List</CardTitle>
          </CardHeader>
          <CardBody>
            <DataTableExtensions
              data={rows}
              columns={columns}
              filter={true}
              filterPlaceholder="Search..."
              exportHeaders={true}
              print={true}
            >
              <DataTable
                noHeader
                pagination
                highlightOnHover
                responsive
                customStyles={customStyles}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default PromoCodeList;
