import React, { useEffect, useState } from "react"; //Suspense
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import MaterialButton from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import API from "langapi/http";
import { useParams, withRouter, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default withRouter(function AddMediaCategories(props) {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const lang = query.get("lang");
  const classes = useStyles();
  let { id } = useParams();

  const initialObject = {
    name: "",
    lang: "",
    route: "",
  };

  const [mediaCategory, setMediaCategory] = useState({ ...initialObject });
  const [isEdit, setIsEdit] = useState(false);
  const [selectedLang, setSelectedLang] = useState(lang || "en");

  useEffect(() => {
    if (id && id != null) {
      setIsEdit(true);
      API.get(`/media-category/${id}?lang=${selectedLang}`).then((response) => {
        if (response.status === 200) {
          let data = { ...response?.data };
          if (response?.data) {
            setMediaCategory({ ...mediaCategory, ...data });
          } else {
            setMediaCategory({ ...initialObject });
          }
        }
      });
    }
  }, [selectedLang]);

  const handleInputChange = (e) => {
    let updatedMediaCategory = { ...mediaCategory };
    updatedMediaCategory[e.target.name] = e.target.value;
    setMediaCategory(updatedMediaCategory);
  };

  const handlerouteChange = (e) => {
    let updatedMediaCategory = { ...mediaCategory };
    let updatedValue = e.target.value.replace(/\s+/g, "-");
    updatedValue = updatedValue.replace(/--/g, "-");
    updatedMediaCategory[e.target.name] = updatedValue.toLowerCase();
    setMediaCategory(updatedMediaCategory);
  };

  const handleSubmit = () => {
    let lang = selectedLang;
    let finalMediaCategory = { ...mediaCategory, lang };

    if (!finalMediaCategory.name || finalMediaCategory.name == "") {
      alert("Please Enter Name before Submiting");
      return false;
    }
    if (!finalMediaCategory.route || finalMediaCategory.route == "") {
      alert("Please Enter route before Submiting");
      return false;
    }

    let token = localStorage.getItem("authToken") || "";
    API.post(`/media-category`, finalMediaCategory, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          alert("Media Category Added");
          setMediaCategory({ ...initialObject });
          props.history.push(`/admin/media-categories?lang=${selectedLang}`);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChange = (event) => {
    if (event.target.value != selectedLang) {
      setSelectedLang(event.target.value);
    }
  };

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader
          color="primary"
          className="d-flex justify-content-between align-items-center"
        >
          <h4 style={{ fontWeight: "400" }} className="mb-0">
            {isEdit ? "Edit" : "Add"} Media Category
          </h4>
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: "20%", color: "white" }}
          >
            <InputLabel id="lang" style={{ color: "white" }}>
              Select Language
            </InputLabel>
            <Select
              labelId="lang"
              id="lang"
              name="lang"
              value={selectedLang}
              label="Select Language"
              fullWidth
              style={{ color: "white" }}
              onChange={handleChange}
            >
              <MenuItem value={"en"}>En</MenuItem>
              <MenuItem value={"ar"}>AR</MenuItem>
            </Select>
          </FormControl>
        </CardHeader>
        <CardBody>
          <h4 style={{ fontWeight: "400" }} className="mt-3">
            General Information
          </h4>
          <Grid container spacing={2} style={{ display: "flex" }}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Name"
                    value={mediaCategory.name}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    size="small"
                    helperText={""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="route"
                    name="route"
                    label="Route"
                    value={mediaCategory.route}
                    variant="outlined"
                    fullWidth
                    onChange={handlerouteChange}
                    size="small"
                    helperText={""}
                    disabled={isEdit ? true : false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardBody>
      </Card>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <MaterialButton
            onClick={handleSubmit}
            style={{ float: "right" }}
            variant="contained"
            color="primary"
            size="large"
          >
            Submit
          </MaterialButton>
        </Grid>
      </Grid>
    </div>
  );
});
