import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { MenuItem, Select, FormControl, TextField } from "@material-ui/core";
import CKEditor from "ckeditor4-react";
import { ckEditorConfig } from "utils/data";
import API from "langapi/http";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function PageAdd() {
  const classes = useStyles();
  const [selectedLang, setSelectedLang] = useState("en");
  const [pages, setPages] = useState({
    name: "",
    route: "",
    content: "",
    short_description: "<p>Short description goes here!</p>",
    lang: "",
  });

  const handleInputChange = (e) => {
    let updatedPages = { ...pages };
    updatedPages[e.target.name] = e.target.value;
    setPages(updatedPages);
  };

  const handleSubmit = () => {
    let lang = selectedLang;
    let finalPage = { ...pages, lang };
    if (!finalPage.name || finalPage.name == "") {
      alert("Please Enter Name before Submiting");
      return false;
    }
    if (!finalPage.route || finalPage.route == "") {
      alert("Please Enter route before Submiting");
      return false;
    }
    let token = localStorage.getItem("authToken") || "";
    API.post(`/pages`, finalPage, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          alert("Page Added Successfully!");
        }
      })
      .catch((err) => alert("Something went wrong"));
  };

  const handleChange = (event) => {
    if (event.target.value != selectedLang) {
      setSelectedLang(event.target.value);
    }
  };

  return (
    <div>
      <div className={classes.root}>
        <Card>
          <CardHeader
            color="primary"
            className="d-flex justify-content-between align-items-center"
          >
            <h4 style={{ fontWeight: "400" }} className="mb-0">
              Add Page
            </h4>
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: "20%", color: "white" }}
            >
              <InputLabel id="lang" style={{ color: "white" }}>
                Select Language
              </InputLabel>
              <Select
                labelId="lang"
                id="lang"
                name="lang"
                value={selectedLang}
                label="Select Language"
                fullWidth
                style={{ color: "white" }}
                onChange={handleChange}
              >
                <MenuItem value={"en"}>En</MenuItem>
                <MenuItem value={"ar"}>AR</MenuItem>
              </Select>
            </FormControl>
          </CardHeader>
          <CardBody>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="name"
                  name="name"
                  label="Name"
                  value={pages.name}
                  variant="outlined"
                  fullWidth
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  required
                  id="route"
                  name="route"
                  label="route"
                  value={pages.route}
                  variant="outlined"
                  fullWidth
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <p>Short Description</p>
                <CKEditor
                  config={ckEditorConfig}
                  onBeforeLoad={(CKEDITOR) =>
                    (CKEDITOR.disableAutoInline = true)
                  }
                  data={pages.short_description}
                  onChange={(e) =>
                    setPages({
                      ...pages,
                      short_description: e.editor.getData(),
                    })
                  }
                />
              </Grid>
            </Grid>
          </CardBody>
        </Card>
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          Add Page
        </Button>
      </div>
    </div>
  );
}
