import React, { useEffect, useState } from "react"; //Suspense
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MaterialButton from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { TextField } from "@material-ui/core";
import API from "langapi/http";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { useParams, withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default withRouter(function AddPromoCode(props) {
  const classes = useStyles();
  let { id } = useParams();

  const initialObject = {
    name: "",
    value: "",
    usage: "",
    code_count: "",
    start_date: "",
    end_date: "",
  };
  const [room, setRoom] = useState({ ...initialObject });
  const [isEdit, setIsEdit] = useState(false);
  const [date, setDate] = useState("");

  useEffect(() => {
    if (id && id != null) {
      setIsEdit(true);
      API.get(`/promo-codes/${id}`).then((response) => {
        if (response.status === 200) {
          let data = { ...response?.data };
          if (response?.data) {
            setRoom({ ...room, ...data });
          } else {
            setRoom({ ...initialObject });
          }
        }
      });
    }
  }, []);

  const handleInputChange = (e) => {
    let updatedRoom = { ...room };
    updatedRoom[e.target.name] = e.target.value;
    setRoom(updatedRoom);
  };

  const handleSlugChange = (e) => {
    let updatedRoom = { ...room };
    let updatedValue = e.target.value.replace(/\s+/g, "-");
    updatedValue = updatedValue.replace(/--/g, "-");
    updatedRoom[e.target.name] = updatedValue.toLowerCase();
    setRoom(updatedRoom);
  };

  const handleSubmit = () => {
    let finalRoom = room;

    if (!finalRoom.name || finalRoom.name == "") {
      alert("Please Enter Name before Submiting");
      return false;
    }
    if (!finalRoom.value || finalRoom.value == "") {
      alert("Please Enter Discount in % before Submiting");
      return false;
    }
    if (!finalRoom.usage || finalRoom.usage == "") {
      alert("Please Enter Usage in Total before Submiting");
      return false;
    }
    if (!finalRoom.code_count || finalRoom.code_count == "") {
      alert("Please Enter Usage Count before Submiting");
      return false;
    }

    let token = localStorage.getItem("authToken") || "";
    API.post(`/promo-codes`, finalRoom, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        alert("Promo Codes Added");
        setRoom({ ...initialObject });
        props.history.push("/admin/promo-codes");
      }
    });
  };

  const onSelect = (dateValue, states) => {
    if (dateValue.length > 1) {
      let formMonth = String(dateValue[0].getMonth() + 1).padStart(2, "0");
      let toMonth = String(dateValue[1].getMonth() + 1).padStart(2, "0");
      let from =
        dateValue[0].getFullYear() +
        "-" +
        formMonth +
        "-" +
        String(dateValue[0].getDate()).padStart(2, "0");
      let to =
        dateValue[1].getFullYear() +
        "-" +
        toMonth +
        "-" +
        String(dateValue[1].getDate()).padStart(2, "0");
      setRoom({ ...room, start_date: from, end_date: to });
    }
  };

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader
          color="primary"
          className="d-flex justify-content-between align-items-center"
        >
          <h4 style={{ fontWeight: "400" }} className="mb-0">
            {isEdit ? "Edit" : "Add"} Promo Code
          </h4>
        </CardHeader>
        <CardBody>
          <h4 style={{ fontWeight: "400" }} className="mt-3">
            General Information
          </h4>
          <Grid container spacing={2} style={{ display: "flex" }}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Flatpickr
                    className="dateRangeForPromo"
                    value={date}
                    mode="range"
                    options={{
                      dateFormat: "Y-m-d",
                      mode: "range",
                    }}
                    onChange={(date) => {
                      onSelect(date);
                      setDate(date);
                    }}
                    placeholder={"Select Date Here"}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Name"
                    value={room.name}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    size="small"
                    helperText={""}
                    disabled={isEdit ? true : false}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="value"
                    name="value"
                    label="Discount in % *"
                    value={room.value}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    size="small"
                    helperText={""}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="usage"
                    name="usage"
                    label="Usage in Total *"
                    value={room.usage}
                    variant="outlined"
                    fullWidth
                    onChange={handleSlugChange}
                    size="small"
                    helperText={""}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="code_count"
                    name="code_count"
                    label="Usage Count *"
                    value={room.code_count}
                    variant="outlined"
                    fullWidth
                    onChange={handleSlugChange}
                    size="small"
                    helperText={""}
                  />
                </Grid>
              </Grid>
            </Grid>
            <div className="clearfix clear-fix"></div>
            <hr />
          </Grid>
        </CardBody>
      </Card>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <MaterialButton
            onClick={handleSubmit}
            style={{ float: "right" }}
            variant="contained"
            color="primary"
            size="large"
          >
            Submit
          </MaterialButton>
        </Grid>
      </Grid>
    </div>
  );
});
