import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Badge, Col, Row } from "reactstrap";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "15px", // override the cell padding for head cells
      paddingRight: "15px",
      fontWeight: "600",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px", // override the cell padding for data cells
      paddingRight: "15px",
      textTransform: "Capitalize",
    },
  },
};

const OrderDetailModal = (props) => {
  const [order, setOrder] = useState(props?.review);

  useEffect(() => {
    setOrder(props?.review);
  }, [props?.review]);

  const [rows, setRows] = useState(props?.review?.order_details);

  useEffect(() => {
    setRows(props?.review?.order_details);
  }, [props?.review?.order_details]);

  let columns = [
    {
      name: "Date",
      selector: "created_at",
      sortable: true,
      cell: (row) => (
        <p className="text-bold-500 mb-0">
          {new Date(row?.created_at).toLocaleDateString()}{" "}
        </p>
      ),
    },
    {
      name: "Price",
      selector: "price",
      sortable: true,
    },
    {
      name: "qty",
      selector: "qty",
      sortable: true,
    },
    {
      name: "Discount",
      selector: "discount",
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: "total",
      sortable: true,
    },
  ];

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modelForOrder"
    >
      <Modal.Header closeButton className="orderdetailModelheader">
        <Modal.Title id="contained-modal-title-vcenter">
          Order Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="orderdetailModelBody">
        <div>
          <Row>
            <Col sm={3}>
              <h6>Order ID:</h6>
            </Col>
            <Col>
              <p>{order?.order_number}</p>
            </Col>
            <Col sm={3}>
              <h6>Sub Total:</h6>
            </Col>
            <Col sm={3}>
              <p>{order?.sub_total}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <h6>User Name:</h6>
            </Col>
            <Col sm={3}>
              <p>{order?.user_detail?.name}</p>
            </Col>
            <Col sm={3}>
              <h6 style={{ fontWeight: "bold" }}>Promo Code:</h6>
            </Col>
            <Col sm={3}>
              <p>{order?.coupon}</p>
            </Col>
          </Row>
          <Row>
            {order?.payment_details?.promo_discount > 0 && (
              <>
                <Col sm={3}>
                  <h6>Promo Code Discount :</h6>
                </Col>
                <Col sm={3}>
                  <p>{(order?.discount).toFixed(2) || ""}</p>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col sm={3}>
              <h6>Email:</h6>
            </Col>
            <Col sm={3}>
              <p>{order?.user_detail?.email}</p>
            </Col>
            <Col sm={3}>
              <h6>Total After promo Code:</h6>
            </Col>
            <Col sm={3}>
              <p>{order?.total}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <h6>Date:</h6>
            </Col>
            <Col sm={3}>
              <p>{new Date(order?.created_at).toLocaleDateString()}</p>
            </Col>
            <Col sm={3}>
              <h6>Shipping Charges:</h6>
            </Col>
            <Col sm={3}>
              <p>{order?.shipping_charges}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <h6>Status:</h6>
            </Col>
            <Col sm={3}>
              {/* {order?.status == "accepted" ? <Badge color="success">Paid</Badge> : <Badge color="warning">Un Paid</Badge> } */}
              <Badge color="primary" className="badgeClassPrimary">
                {order?.status}
              </Badge>
            </Col>
            <Col sm={3}>
              <h6 style={{ fontWeight: "bold" }}>Final Total: </h6>
            </Col>
            <Col sm={3}>
              {/* {order?.status == "accepted" ? <Badge color="success">Paid</Badge> : <Badge color="warning">Un Paid</Badge> } */}
              <p>{order?.total}</p>
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <h6>Products List:</h6>
          <DataTableExtensions
            data={rows}
            columns={columns}
            filter={true}
            filterPlaceholder="Search..."
            exportHeaders={true}
            print={true}
          >
            <DataTable
              noHeader
              pagination
              highlightOnHover
              responsive
              customStyles={customStyles}
            />
          </DataTableExtensions>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderDetailModal;
