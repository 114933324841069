import React, { Component } from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import API from "langapi/http";
import InputLabel from "@material-ui/core/InputLabel";
import { Avatar, Box, Button } from "@material-ui/core";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { Link } from "react-router-dom";
import { AddOutlined, DeleteOutlined, EditOutlined } from "@material-ui/icons";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "15px", // override the cell padding for head cells
      paddingRight: "15px",
      fontWeight: "600",
      fontSize: "15px",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px", // override the cell padding for data cells
      paddingRight: "15px",
      textTransform: "Capitalize",
    },
  },
};

class BlogsList extends Component {
  state = {
    selectedLang: window.location.search.split("=")[1] || "en",
    rows: [],
    columns: [
      {
        name: "Image",
        selector: "featured_img",
        sortable: true,
        minWidth: "100px",
        cell: (row) => <Avatar alt="image" src={row?.featured_img}></Avatar>,
      },
      {
        name: "Title",
        selector: "title",
        sortable: true,
      },
      {
        name: "Description",
        selector: "short_description",
        sortable: true,
        cell: (row) => (
          <code>
            {row?.short_description?.length > 100
              ? row?.short_description?.substr(0, 100) + "..."
              : row?.short_description}
          </code>
        ),
      },
      {
        name: "Actions",
        selector: "route",
        sortable: true,
        cell: (row) => (
          <div className="d-flex nowrap">
            <EditOutlined
              fontSize="small"
              className="action-icon-edit"
              onClick={() =>
                this.props.history.push(
                  `/admin/blogs/edit/${row?.route}?lang=${this.state.selectedLang}`
                )
              }
            />
            <DeleteOutlined
              fontSize="small"
              className="action-icon-delete"
              onClick={() => this.handleDelete(row?.route)}
            />
          </div>
        ),
      },
    ],
  };

  componentDidMount() {
    API.get(`/blogs?lang=${this.state.selectedLang}`)
      .then((response) => {
        let rows = response.data;
        this.setState({ rows });
      })
      .catch((err) => console.log(err));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedLang !== this.state.selectedLang) {
      API.get(`/blogs?lang=${this.state.selectedLang}`)
        .then((response) => {
          let rows = response.data;
          if (this.state.rows != rows) {
            this.setState({ rows });
          }
        })
        .catch((err) => console.log(err));
    }
  }

  handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this ?")) {
      let token = localStorage.getItem("authToken") || "";
      API.delete(`/blogs/${id}?lang=${this.state.selectedLang}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            alert("Blog deleted successfully!");
          }
        })
        .then(() => {
          API.get(`/blogs?lang=${this.state.selectedLang}`).then((response) => {
            let rows = response.data;
            this.setState({ rows });
          });
        })
        .catch((err) => console.log(err));
    }
  };

  handleChange = (event) => {
    if (event.target.value != this.state.selectedLang) {
      this.setState({ selectedLang: event.target.value });
    }
  };

  render() {
    let { rows, columns } = this.state;
    return (
      <div>
        <Box marginBottom={4}>
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/admin/blogs/add">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddOutlined />}
              >
                Add Blog
              </Button>
            </Link>
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: "20%" }}
            >
              <InputLabel id="lang">Select Language</InputLabel>
              <Select
                labelId="lang"
                id="lang"
                name="lang"
                value={this.state.selectedLang}
                label="Select Language"
                fullWidth
                onChange={this.handleChange}
              >
                <MenuItem value={"en"}>En</MenuItem>
                <MenuItem value={"ar"}>AR</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Box>

        <Card>
          <CardHeader>
            <CardTitle>Blogs List</CardTitle>
          </CardHeader>
          <CardBody>
            <DataTableExtensions
              data={rows}
              columns={columns}
              filter={true}
              filterPlaceholder="Search..."
              exportHeaders={true}
              print={true}
            >
              <DataTable
                noHeader
                pagination
                highlightOnHover
                responsive
                customStyles={customStyles}
              />
            </DataTableExtensions>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default BlogsList;
