import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MaterialButton from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  FormControl,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import CKEditor from "ckeditor4-react";
import { ckEditorConfig } from "utils/data";
import { Image } from "@material-ui/icons";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useParams, useLocation } from "react-router-dom";
import API from "langapi/http";
import GalleryDialog from "../../../Common/GalleryDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function AddPrivacyPolicy() {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const lang = query.get("lang");
  const pageId = useParams().id;
  const classes = useStyles();

  let initObj = {
    banner: {
      image: "",
    },
    intro: {
      heading: "",
      description: "",
    },
  };

  let seoObj = {
    id: 0,
    post_id: pageId || 0,
    meta_title: "",
    meta_description: "",
    schema_markup: "",
    is_followed: true,
    is_indexed: true,
    is_indexed_or_is_followed: "1,1",
  };
  const [home, setHome] = useState(initObj);
  const [seoInfo, setSeoInfo] = useState(seoObj);
  const [currentSection, setCurrentSection] = useState("");
  const [pageData, setPageData] = useState({});
  const [imagesData, setImagesData] = useState([]);
  const [showGallery, setShowGallery] = useState(false);
  const [isSingle, setIsSingle] = useState(true);
  const [selectedLang, setSelectedLang] = useState(lang || "en");

  useEffect(() => {
    var splitUrl = window.location.href.split("/");
    let route = splitUrl[splitUrl.length - 1];
    const finalRoute = route.split("?")[0];
    API.get(`/pages/${finalRoute}?lang=${selectedLang}`)
      .then((response) => {
        if (response?.status === 200) {
          setPageData(response.data);
          if (response.data.content) {
            let data = response.data.content;
            setHome(data);
            if (response.data.content.meta) {
              setSeoInfo(response.data.content.meta);
            }
          }
        }
      })
      .catch((err) => console.log(err));

    if (!imagesData.length > 0) {
      getGalleryImages();
    }
  }, [selectedLang]);

  const getGalleryImages = () => {
    API.get(`/uploads`)
      .then((response) => {
        if (response.status === 200) {
          setImagesData(
            response.data?.data?.map((x) => ({ ...x, isChecked: false }))
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const handleInputChange = (e, section) => {
    let updatedAbout = { ...home };
    updatedAbout[section][e.target.name] = e.target.value;
    setHome(updatedAbout);
  };

  const handleEditorChange = (e, section) => {
    let updatedAbout = { ...home };
    updatedAbout[section].description = e.editor.getData();
    setHome(updatedAbout);
  };

  const handleImageSelect = (e, index, section) => {
    setTimeout(() => {
      setShowGallery(false);
    }, 500);

    if (e.target.checked) {
      if (section == "banner") {
        setHome({
          ...home,
          [section]: { ...home[section], image: imagesData[index].avatar },
        });
      }
    } else {
      setHome({
        ...home,
        [section]: { ...home[section], section_avatar: "" },
      });
      setImagesData(
        imagesData.map((x, i) => {
          if (i === index) {
            return {
              ...x,
              isChecked: false,
            };
          } else {
            return x;
          }
        })
      );
    }
  };

  const handleSEOInputChange = (e) => {
    let updatedSeoInfo = { ...seoInfo };
    updatedSeoInfo[e.target.name] = e.target.value;
    setSeoInfo(updatedSeoInfo);
  };

  const handleSubmit = () => {
    let updatedhome = { ...home };
    updatedhome.meta = { ...seoInfo };
    let updatedData = { ...pageData };
    updatedData.content = updatedhome;
    let token = localStorage.getItem("authToken") || "";
    API.post(`/pages`, updatedData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          alert("Record Updated");
        }
      })
      .catch((err) => alert("Something went wrong"));
  };

  return (
    <div>
      <div className={classes.root}>
        <Card>
          <CardHeader
            color="primary"
            className="d-flex justify-content-between align-items-center"
          >
            <h4 className="mb-0">Add Privacy Policy Page</h4>
          </CardHeader>
          <CardBody>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panelaa-content"
                id="panelaa-header"
              >
                <Typography className={classes.heading}>Banner</Typography>
              </AccordionSummary>
              <AccordionDetails className="accordian-flex-column">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} style={{ marginBottom: "15px" }}>
                    <div className="thumbnail-preview-wrapper-large img-thumbnail">
                      {home?.banner?.image !== "" ? (
                        <img src={home?.banner?.image} alt={""} />
                      ) : (
                        <img
                          src="https://cpworldgroup.com/wp-content/uploads/2021/01/placeholder.png"
                          alt=""
                        />
                      )}
                    </div>
                    <Fragment>
                      <MaterialButton
                        variant="outlined"
                        color="primary"
                        startIcon={<Image />}
                        className="mt-1"
                        fullWidth
                        size="large"
                        onClick={() => {
                          setIsSingle(true);
                          setCurrentSection("banner");
                          setShowGallery(true);
                        }}
                      >
                        Upload Banner Image
                      </MaterialButton>
                    </Fragment>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panelaa-content"
                id="panelaa-header"
              >
                <Typography className={classes.heading}>
                  Introduction
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="heading"
                      name="heading"
                      label="Heading"
                      value={home?.intro?.heading}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => handleInputChange(e, "intro")}
                      size="medium"
                      style={{ marginBottom: "1rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <p>Description</p>
                    <CKEditor
                      config={ckEditorConfig}
                      onBeforeLoad={(CKEDITOR) =>
                        (CKEDITOR.disableAutoInline = true)
                      }
                      data={home?.intro?.description}
                      onChange={(e) => handleEditorChange(e, "intro")}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  SEO Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="meta_title"
                      name="meta_title"
                      label="Meta Title"
                      value={seoInfo.meta_title}
                      variant="outlined"
                      fullWidth
                      onChange={handleSEOInputChange}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="meta_description"
                      name="meta_description"
                      label="Meta Description"
                      value={seoInfo.meta_description}
                      variant="outlined"
                      fullWidth
                      onChange={handleSEOInputChange}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="schema_markup"
                      name="schema_markup"
                      label="Schema Markup"
                      value={seoInfo.schema_markup}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      rowsMax={4}
                      onChange={handleSEOInputChange}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="is_followed"
                        row
                        defaultChecked
                        name="is_followed"
                        value={seoInfo.is_followed}
                        onChange={(e) => {
                          setSeoInfo({
                            ...seoInfo,
                            is_followed: !seoInfo.is_followed,
                          });
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Follow"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No Follow"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="is_indexed"
                        row
                        defaultChecked
                        name="is_indexed"
                        value={seoInfo.is_indexed}
                        onChange={(e) => {
                          setSeoInfo({
                            ...seoInfo,
                            is_indexed: !seoInfo.is_indexed,
                          });
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Index"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No Index"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </CardBody>
        </Card>
        <Grid item xs={12} sm={12}>
          <MaterialButton
            onClick={() => handleSubmit()}
            size="large"
            color="primary"
            variant="contained"
          >
            Update Section
          </MaterialButton>
        </Grid>
      </div>
      <GalleryDialog
        isSingle={isSingle}
        section={currentSection}
        open={showGallery}
        handleImageSelect={handleImageSelect}
        handleClose={() => {
          setShowGallery(false);
        }}
        refreshGallery={getGalleryImages}
        data={imagesData}
      />
    </div>
  );
}
