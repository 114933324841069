import React, { Fragment, useEffect, useState } from "react"; //Suspense
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import MaterialButton from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  OutlinedInput,
  ListItemText,
} from "@material-ui/core";
import CKEditor from "ckeditor4-react";
import API from "langapi/http";
import SelectedImagesThumbnails from "../Common/SelectedImagesThumbnails";
import Checkbox from "@material-ui/core/Checkbox";
import { useParams, withRouter, useLocation } from "react-router-dom";
import GalleryDialog from "views/Common/GalleryDialog";
import { Image } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default withRouter(function AddProduct(props) {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const lang = query.get("lang");
  const classes = useStyles();
  let { id } = useParams();

  const initialObject = {
    name: "",
    route: "",
    price: "",
    lang: "",
    banner_img: "",
    featured_img1: "",
    featured_img2: "",
    slider_img: [],
    short_description: "",
    long_description: "",
    benifits: "",
    method_of_use: "",
    composition: "",
    gallery: [],
    status: "1",
    pack_of: "",
    why: "",
    ingredients: [],
    seo: {
      meta_title: "",
      meta_description: "",
      schema_markup: "",
    },
  };

  const [products, setProducts] = useState({ ...initialObject });
  const [isEdit, setIsEdit] = useState(false);
  const [imagesData, setImagesData] = useState([]);
  const [imagesDataBackup, setImagesDataBackup] = useState([]);
  const [showGallery, setShowGallery] = useState(false);
  const [isSingle, setIsSingle] = useState(false);
  const [selectedLang, setSelectedLang] = useState(lang || "en");
  const [uploadsPreview, setUploadsPreview] = useState(null);
  const [imageType, setImageType] = useState("");
  const [ingredientsData, setIngredientsData] = useState([]);

  const useStyles2 = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const theme = useTheme();
  const classes2 = useStyles2();

  function getStyles(name, personName, theme) {
    const index = personName?.findIndex((object) => {
      return object.id === name.id;
    });
    return {
      fontWeight:
        index === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  useEffect(() => {
    if (id && id != null) {
      setIsEdit(true);
      API.get(`/products/${id}?lang=${selectedLang}`).then((response) => {
        if (response.status === 200) {
          if (response?.data) {
            setProducts(response?.data);
          } else {
            setProducts({ ...initialObject });
          }
        }
      });
    }
    API.get(`/ingredient?lang=${selectedLang}`).then((response) => {
      if (response.status === 200) {
        if (response?.data) {
          setIngredientsData(response?.data);
        }
      }
    });

    if (!imagesData.length > 0) {
      getGalleryImages();
    }
  }, [selectedLang]);

  const getGalleryImages = () => {
    API.get(`/uploads`)
      .then((response) => {
        if (response.status === 200) {
          setImagesData(
            response.data?.data?.map((x) => ({ ...x, isChecked: false }))
          );
          setImagesDataBackup(
            response.data?.data?.map((x) => ({ ...x, isChecked: false }))
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const handleInputChange = (e) => {
    let updatedproducts = { ...products };
    updatedproducts[e.target.name] = e.target.value;
    setProducts(updatedproducts);
  };

  const handleSEOInputChange = (e) => {
    let updatedproducts = { ...products };
    updatedproducts.seo[e.target.name] = e.target.value;
    setProducts(updatedproducts);
  };

  const handleSlugChange = (e) => {
    let updatedproducts = { ...products };
    let updatedValue = e.target.value.replace(/\s+/g, "-");
    updatedValue = updatedValue.replace(/--/g, "-");
    updatedproducts[e.target.name] = updatedValue.toLowerCase();
    setProducts(updatedproducts);
  };

  const handleRemoveSelectedImage = (x, arrayListType, index) => {
    switch (arrayListType) {
      case "gallery":
        let updateDataGallery = products?.gallery?.filter((u) => u.id !== x.id);
        setProducts({ ...products, gallery: updateDataGallery });
        break;
      case "slider_img":
        let updateDataSliderImages = products?.slider_img?.filter(
          (u) => u.id !== x.id
        );
        setProducts({ ...products, slider_img: updateDataSliderImages });
        break;
      default:
        return setUploadsPreview(uploadsPreview.filter((u) => u.id !== x.id));
    }
  };

  const handleImageSelect = (e, index) => {
    if (e.target.checked) {
      if (imageType == "banner_img") {
        setProducts({ ...products, banner_img: imagesData[index].avatar });
        setTimeout(() => {
          setShowGallery(false);
        }, 500);
      } else if (imageType == "featured_img1") {
        setProducts({ ...products, featured_img1: imagesData[index].avatar });
        setTimeout(() => {
          setShowGallery(false);
        }, 500);
      } else if (imageType == "featured_img2") {
        setProducts({ ...products, featured_img2: imagesData[index].avatar });
        setTimeout(() => {
          setShowGallery(false);
        }, 500);
      } else if (imageType == "gallery") {
        const index2 = products?.gallery?.findIndex(
          (img) => img.id === imagesData[index].id
        );
        if (index2 === -1) {
          setProducts({
            ...products,
            gallery: [
              ...products.gallery,
              {
                avatar: imagesData[index].avatar,
                id: imagesData[index].id,
                url: imagesData[index].url,
                is_default: false,
              },
            ],
          });
        } else {
          alert("This Image is already selected");
        }
      } else if (imageType == "slider_img") {
        const index2 = products?.slider_img?.findIndex(
          (img) => img.id === imagesData[index].id
        );
        if (index2 === -1) {
          setProducts({
            ...products,
            slider_img: [
              ...products.slider_img,
              {
                avatar: imagesData[index].avatar,
                id: imagesData[index].id,
                url: imagesData[index].url,
                is_default: false,
              },
            ],
          });
        } else {
          alert("This Image is already selected");
        }
      }
      let imagesDataUpdated = imagesData.map((x, i) => {
        if (i === index) {
          return {
            ...x,
            isChecked: true,
          };
        } else {
          return x;
        }
      });
      setImagesData(imagesDataUpdated);
    } else {
      if (imageType == "banner_img") {
      } else if (imageType == "featured_img1") {
      } else if (imageType == "gallery") {
        let updateData = products?.gallery?.filter(
          (u) => u.id !== imagesData[index].id
        );
        setProducts({ ...products, gallery: updateData });
      } else if (imageType == "slider_img") {
        let updateData = products?.slider_img?.filter(
          (u) => u.id !== imagesData[index].id
        );
        setProducts({ ...products, slider_img: updateData });
      }
      setImagesData(
        imagesData.map((x, i) => {
          if (i === index) {
            return {
              ...x,
              isChecked: false,
            };
          } else {
            return x;
          }
        })
      );
    }
  };

  const handleSubmit = () => {
    let lang = selectedLang;
    let finalproducts = { ...products, lang };

    if (!finalproducts.name || finalproducts.name == "") {
      alert("Please Enter name before Submiting");
      return false;
    }
    if (!finalproducts.route || finalproducts.route == "") {
      alert("Please Enter Route before Submiting");
      return false;
    }
    if (!finalproducts.featured_img1 || finalproducts.featured_img1 == "") {
      alert("The featured image field is required.");
      return false;
    }
    if (!finalproducts.featured_img2 || finalproducts.featured_img2 == "") {
      alert("The featured image field is required.");
      return false;
    }
    if (
      !finalproducts.long_description ||
      finalproducts.long_description == ""
    ) {
      alert("The long description field is required.");
      return false;
    }

    let token = localStorage.getItem("authToken") || "";
    API.post(`/products`, finalproducts, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response?.data?.error) {
          alert(response?.data?.error);
        } else if (response?.status === 200) {
          alert("Product Added");
          setProducts({ ...initialObject });
          props.history.push(`/admin/products?lang=${selectedLang}`);
        } else {
          alert("Something went wrong.");
        }
      })
      .catch((err) => {
        alert("Something went wrong.");
        console.log(err);
      });
  };

  const handleTopSellingCheckBox = (e) => {
    let product = { ...products };
    product[e.target.name] = e.target.checked === true ? 1 : 0;
    setProducts(product);
  };

  //.......multi selct code

  const handleChangeMultiple = (event) => {
    const {
      target: { value },
    } = event;

    setProducts({
      ...products,
      ingredients: typeof value === "string" ? value.split(",") : value,
    });
  };

  const handleChange = (event) => {
    if (event.target.value != selectedLang) {
      setSelectedLang(event.target.value);
    }
  };
  return (
    <div className={classes.root}>
      <Card>
        <CardHeader
          color="primary"
          className="d-flex justify-content-between align-items-center"
        >
          <h4 style={{ fontWeight: "400" }} className="mb-0">
            {isEdit ? "Edit" : "Add"} Product
          </h4>
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: "20%", color: "white" }}
          >
            <InputLabel id="lang" style={{ color: "white" }}>
              Select Language
            </InputLabel>
            <Select
              labelId="lang"
              id="lang"
              name="lang"
              value={selectedLang}
              label="Select Language"
              fullWidth
              style={{ color: "white" }}
              onChange={handleChange}
            >
              <MenuItem value={"en"}>En</MenuItem>
              <MenuItem value={"ar"}>AR</MenuItem>
            </Select>
          </FormControl>
        </CardHeader>
        <CardBody>
          <h4 style={{ fontWeight: "400" }} className="mt-3">
            General Information
          </h4>
          <Grid container spacing={2} style={{ display: "flex" }}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Name"
                    value={products.name}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    size="small"
                    helperText={""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="route"
                    name="route"
                    label="Route"
                    value={products.route}
                    variant="outlined"
                    fullWidth
                    onChange={handleSlugChange}
                    size="small"
                    helperText={""}
                    disabled={isEdit === true ? true : false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="price"
                    name="price"
                    label="Price"
                    value={products.price}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    size="small"
                    helperText={""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="pack_of"
                    name="pack_of"
                    label="Pack of"
                    value={products.pack_of}
                    variant="outlined"
                    fullWidth
                    onChange={handleInputChange}
                    size="small"
                    helperText={""}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl className={classes2.formControl}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Ingredients
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  name="ingredients"
                  value={products?.ingredients}
                  onChange={handleChangeMultiple}
                  input={<OutlinedInput label="Select Ingredients" />}
                  renderValue={(selected) => selected?.join(", ")}
                  MenuProps={MenuProps}
                >
                  {ingredientsData?.map((name) => (
                    <MenuItem key={name?.id} value={name?.id}>
                      <Checkbox
                        checked={products?.ingredients?.indexOf(name?.id) > -1}
                      />
                      <ListItemText primary={name?.title} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <div className="thumbnail-preview-wrapper img-thumbnail">
                    {products?.banner_img ? (
                      <img
                        src={products?.banner_img}
                        alt={products.alt_text || ""}
                      />
                    ) : (
                      <img
                        src={require("./../../assets/img/placeholder.png")}
                        alt=""
                      />
                    )}
                  </div>
                  <Fragment>
                    <MaterialButton
                      variant="outlined"
                      color="primary"
                      startIcon={<Image />}
                      className="mt-1"
                      fullWidth
                      onClick={() => {
                        setIsSingle(true);
                        setShowGallery(true);
                        setImageType("banner_img");
                        setImagesData(imagesDataBackup);
                      }}
                    >
                      {isEdit ? "Change" : "Upload"} Banner Image
                    </MaterialButton>
                  </Fragment>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <div className="thumbnail-preview-wrapper img-thumbnail">
                    {products?.featured_img1 ? (
                      <img
                        src={products?.featured_img1}
                        alt={products.alt_text || ""}
                      />
                    ) : (
                      <img
                        src={require("./../../assets/img/placeholder.png")}
                        alt=""
                      />
                    )}
                  </div>
                  <Fragment>
                    <MaterialButton
                      variant="outlined"
                      color="primary"
                      startIcon={<Image />}
                      className="mt-1"
                      fullWidth
                      onClick={() => {
                        setIsSingle(true);
                        setShowGallery(true);
                        setImageType("featured_img1");
                        setImagesData(imagesDataBackup);
                      }}
                    >
                      {isEdit ? "Change" : "Upload"} Featured Image
                    </MaterialButton>
                  </Fragment>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <div className="thumbnail-preview-wrapper img-thumbnail">
                    {products?.featured_img2 ? (
                      <img
                        src={products?.featured_img2}
                        alt={products.alt_text || ""}
                      />
                    ) : (
                      <img
                        src={require("./../../assets/img/placeholder.png")}
                        alt=""
                      />
                    )}
                  </div>
                  <Fragment>
                    <MaterialButton
                      variant="outlined"
                      color="primary"
                      startIcon={<Image />}
                      className="mt-1"
                      fullWidth
                      onClick={() => {
                        setIsSingle(true);
                        setShowGallery(true);
                        setImageType("featured_img2");
                        setImagesData(imagesDataBackup);
                      }}
                    >
                      {isEdit ? "Change" : "Upload"} Featured Image
                    </MaterialButton>
                  </Fragment>
                </Grid>
              </Grid>
            </Grid>

            <div className="clearfix clear-fix"></div>
            {/* GALLERY DIALOG BOX START */}

            <GalleryDialog
              open={showGallery}
              handleImageSelect={handleImageSelect}
              handleClose={() => {
                setShowGallery(false);
              }}
              refreshGallery={getGalleryImages}
              data={imagesData}
            />
            {/* GALLERY DIALOG BOX END */}
          </Grid>
          <hr />
          <Grid item xs={12} sm={12}>
            <hr />
            <h4 style={{ fontWeight: "400" }}>Short Description</h4>
            <CKEditor
              onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
              data={products.short_description}
              onChange={(e) =>
                setProducts({
                  ...products,
                  short_description: e.editor.getData(),
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <hr />
            <h4 style={{ fontWeight: "400" }}>Detailed Content</h4>

            <CKEditor
              onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
              data={products.long_description}
              onChange={(e) =>
                setProducts({
                  ...products,
                  long_description: e.editor.getData(),
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <hr />
            <h4 style={{ fontWeight: "400" }}>Benifits</h4>

            <CKEditor
              onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
              data={products.benifits}
              onChange={(e) =>
                setProducts({
                  ...products,
                  benifits: e.editor.getData(),
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <hr />
            <h4 style={{ fontWeight: "400" }}>Method of Use</h4>

            <CKEditor
              onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
              data={products.method_of_use}
              onChange={(e) =>
                setProducts({
                  ...products,
                  method_of_use: e.editor.getData(),
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <hr />
            <h4 style={{ fontWeight: "400" }}>Composition</h4>

            <CKEditor
              onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
              data={products.composition}
              onChange={(e) =>
                setProducts({
                  ...products,
                  composition: e.editor.getData(),
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <hr />
            <h4 style={{ fontWeight: "400" }}>Why</h4>

            <CKEditor
              onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
              data={products.why}
              onChange={(e) =>
                setProducts({
                  ...products,
                  why: e.editor.getData(),
                })
              }
            />
          </Grid>
        </CardBody>
      </Card>
      {/* MULTIPLE IMAGES UPLOAD SECTION START */}
      <Card>
        <CardBody>
          <h3>Slider Images</h3>
          <p>
            <em>Please select images from gallery.</em>
          </p>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <MaterialButton
                variant="outlined"
                color="primary"
                onClick={() => {
                  setIsSingle(false);
                  setShowGallery(true);
                  setImageType("slider_img");
                  setImagesData(imagesDataBackup);
                }}
              >
                Select Slider Images
              </MaterialButton>
            </Grid>
            {products?.slider_img?.map((x) => (
              <SelectedImagesThumbnails
                x={x}
                handleRemoveSelectedImage={(r) =>
                  handleRemoveSelectedImage(r, "slider_img")
                }
              />
            ))}
            <div className="clearfix clear-fix"></div>
            {/* GALLERY DIALOG BOX START */}
            <GalleryDialog
              isSingle={isSingle}
              open={showGallery}
              handleImageSelect={handleImageSelect}
              handleClose={() => {
                setShowGallery(false);
                setUploadsPreview([]);
              }}
              refreshGallery={getGalleryImages}
              data={imagesData}
              selectedData={products?.slider_img}
            />
            {/* GALLERY DIALOG BOX END */}
          </Grid>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <h3>Gallery</h3>
          <p>
            <em>Please select images from gallery.</em>
          </p>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <MaterialButton
                variant="outlined"
                color="primary"
                onClick={() => {
                  setIsSingle(false);
                  setShowGallery(true);
                  setImageType("gallery");
                  setImagesData(imagesDataBackup);
                }}
              >
                Select Gallery Images
              </MaterialButton>
            </Grid>
            {products?.gallery?.map((x) => (
              <SelectedImagesThumbnails
                x={x}
                handleRemoveSelectedImage={(r) =>
                  handleRemoveSelectedImage(r, "gallery")
                }
              />
            ))}
            <div className="clearfix clear-fix"></div>
            {/* GALLERY DIALOG BOX START */}
            <GalleryDialog
              isSingle={isSingle}
              open={showGallery}
              handleImageSelect={handleImageSelect}
              handleClose={() => {
                setShowGallery(false);
                setUploadsPreview([]);
              }}
              refreshGallery={getGalleryImages}
              data={imagesData}
              selectedData={products?.gallery}
            />
            {/* GALLERY DIALOG BOX END */}
          </Grid>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <h3>SEO Information</h3>
          <CardBody
            style={{
              border: "1px solid rgb(201, 194, 194)",
              boxShadow: "0 0px 10px 0 rgba(0, 0, 0, 0.14)",
              marginBottom: "5px",
            }}
          >
            <Grid container spacing={2} style={{ display: "flex" }}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="meta_title"
                      name="meta_title"
                      label="Meta Title"
                      value={products?.seo?.meta_title}
                      variant="outlined"
                      fullWidth
                      onChange={handleSEOInputChange}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="meta_description"
                      name="meta_description"
                      label="Meta Description"
                      value={products?.seo?.meta_description}
                      variant="outlined"
                      fullWidth
                      onChange={handleSEOInputChange}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="schema_markup"
                      name="schema_markup"
                      label="Schema Markup"
                      value={products?.seo?.schema_markup}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      rowsMax={4}
                      onChange={handleSEOInputChange}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardBody>
        </CardBody>
      </Card>
      {/* MULTIPLE IMAGES UPLOAD SECTION END */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <MaterialButton
            onClick={handleSubmit}
            style={{ float: "right" }}
            variant="contained"
            color="primary"
            size="large"
          >
            Submit
          </MaterialButton>
        </Grid>
      </Grid>
    </div>
  );
});
