import React, { useEffect, useState } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Accessibility from "@material-ui/icons/Accessibility";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import API from "langapi/http";
import { Checkbox, IconButton } from "@material-ui/core";
import { AddOutlined, DeleteOutlined } from "@material-ui/icons";
import AddTodoDialog from "./AddTodoDialog";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();

  const [recents, setRecents] = useState([]);
  const [todos, setTodos] = useState([]);
  const [showAddTodo, setShowAddTodo] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("authToken") || "";
    API.get("/dashboard-details", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      if (response?.status === 200) {
        setRecents(response.data);
      }
    });
    getTodos();
  }, []);

  const getTodos = () => {
    let token = localStorage.getItem("authToken") || "";
    API.get("/auth/todos", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      if (response?.status === 200) {
        setTodos(response.data);
      }
    });
  };

  const handleStatusChange = (e, index) => {
    let token = localStorage.getItem("authToken") || "";
    let updatedTodo = todos[index];
    updatedTodo.status = e.target.checked;
    API.post(`/auth/todos`, updatedTodo, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response?.status === 200) {
        }
      })
      .then(() => {
        getTodos();
      })
      .catch((err) => {
        alert("Something went wrong");
      });
  };

  const handleTaskDelete = (id) => {
    let token = localStorage.getItem("authToken") || "";
    API.delete(`/auth/todos/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.status === 200) {
          alert("Task deleted successfully.");
        }
      })
      .then(() => {
        getTodos();
      })
      .catch((err) => alert("Something went wrong"));
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Total Products</p>
              <h3 className={classes.cardTitle}>{recents?.totalProducts}</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Total Orders</p>
              <h3 className={classes.cardTitle}>{recents?.totalOrder}</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Total Sales</p>
              <h3 className={classes.cardTitle}>{recents?.totalSales}</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Total Users</p>
              <h3 className={classes.cardTitle}>{recents?.totalUsers}</h3>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success">
              <div
                className="d-flex align-items-center"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <h4 className="mb-0">Todo List</h4>
                  <p className={classes.cardCategoryWhite}>
                    List of all tasks and todos.
                  </p>
                </div>
                <div>
                  <IconButton
                    color="default"
                    style={{ color: "#fff" }}
                    onClick={() => setShowAddTodo(true)}
                  >
                    <AddOutlined />
                  </IconButton>
                </div>
              </div>
            </CardHeader>
            <CardBody style={{ height: "300px", overflowY: "scroll" }}>
              <div
                className="d-flex align-items-center img-thumbnail mb-2"
                style={{ justifyContent: "space-between" }}
              >
                <small
                  style={{ width: "30%", marginBottom: 0, fontWeight: 500 }}
                >
                  Task
                </small>
                <small
                  style={{ width: "30%", marginBottom: 0, fontWeight: 500 }}
                >
                  Date
                </small>
                <small
                  style={{
                    width: "20%",
                    marginBottom: 0,
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Status
                </small>
                <small
                  style={{
                    width: "10%",
                    marginBottom: 0,
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Mark
                </small>
                <small
                  style={{
                    width: "10%",
                    marginBottom: 0,
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Delete
                </small>
              </div>
              {todos?.map((x, index) => (
                <div
                  className="d-flex align-items-center img-thumbnail mb-2"
                  style={{ justifyContent: "space-between" }}
                >
                  <p
                    title={x.todo_description}
                    style={{ width: "30%", marginBottom: 0 }}
                  >
                    {x.name}
                  </p>
                  <p style={{ width: "30%", marginBottom: 0 }}>
                    {new Date(x.updated_at).toLocaleDateString()}
                  </p>
                  <p
                    style={{
                      width: "20%",
                      marginBottom: 0,
                      textAlign: "center",
                    }}
                  >
                    {x.status == 0 ? (
                      <small className="badge badge-warning">pending</small>
                    ) : (
                      <small className="badge badge-success">completed</small>
                    )}
                  </p>
                  <p
                    style={{
                      width: "10%",
                      marginBottom: 0,
                      textAlign: "center",
                    }}
                  >
                    <Checkbox
                      checked={x.status == 0 ? false : true}
                      tabIndex={-1}
                      onChange={(e) => handleStatusChange(e, index)}
                      size="small"
                    />
                  </p>
                  <p
                    style={{
                      width: "10%",
                      marginBottom: 0,
                      textAlign: "center",
                    }}
                  >
                    <DeleteOutlined
                      onClick={() => handleTaskDelete(x.id)}
                      style={{ cursor: "pointer" }}
                      fontSize="small"
                      color="secondary"
                    />
                  </p>
                </div>
              ))}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <AddTodoDialog
        success={() => {
          getTodos();
          setShowAddTodo(false);
        }}
        onClose={() => setShowAddTodo(false)}
        open={showAddTodo}
      />
    </div>
  );
}
